import {
    TASK_QUEUE_ADD, TASK_QUEUE_LOAD, TASK_QUEUE_LOADED, TASK_QUEUE_PATCH,
    TASK_QUEUE_UNLOADED
} from "../constants/actionTypes";

const defaultState = {
    taskQueues: [],
    taskQueueTypes: []
};

export default (state = defaultState, action) => {
    switch (action.type) {
        case TASK_QUEUE_LOAD:
            return {
                ...state,
                taskQueues: action.payload,
            }
        case TASK_QUEUE_LOADED:
            return {
                ...state,
                taskQueueTypes: action.payload
            }
        case TASK_QUEUE_PATCH:
            const newArray = [...state.taskQueues];
            const index = newArray.findIndex(taskQueue => taskQueue.id === action.payload.id);
            newArray[index] = action.payload;

            return {
                ...state,
                taskQueues: newArray,
            }
        case TASK_QUEUE_ADD:
            return {
                ...state,
                taskQueues: [...state.taskQueues, action.payload],
            }
        case TASK_QUEUE_UNLOADED:
            return defaultState;
        default:
            return state;
    }
};
