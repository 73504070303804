import React, { useEffect, useState } from "react"
import SearchSelect from "./SearchSelect"
import {Button} from "primereact/button"
import {Checkbox} from 'primereact/checkbox';
import { Tooltip } from 'primereact/tooltip';
import styles from "./Calculator.module.css"
import agent from "../../agent";
import {InputNumber} from "primereact/inputnumber";
import {DataTable} from "primereact/datatable"
import { Column } from "primereact/column"
import { connect } from "react-redux"
import {RadioButton} from "primereact/radiobutton"
import Form from "./form";
import { CLEAR_ALL_FIELDS, ORGANIZATION_LOAD } from "../../constants/actionTypes";
import {InputText} from "primereact/inputtext";
import 'primeicons/primeicons.css';

const mapStateToProps = state => ({
  ...state.error,
  ...state.orgs,
})

const mapDispatchToProps = dispatch => ({
  clearAllFields: () => {
    dispatch({type: CLEAR_ALL_FIELDS})
  },
  onLoad: (payload) => {
    dispatch({ type: ORGANIZATION_LOAD, payload });
  }
})

const parcelPickupOptions =  [
  {code: 1, name_ru: "От двери"},
  {code: 2, name_ru: "От терминала"},
];
const parcelDeliveryOptions =  [
  {code: 1, name_ru: "До двери"},
  {code: 2, name_ru: "До терминала"},
];

const ESPRZ = 'prz';
const ESPRD = 'prd';

const Calculator = (props) => {
  const [from, setFrom] = useState({ kato: '' });
  const [to, setTo] = useState({ kato: '' });
  const [cargoValue, setCargoValue] = useState(null);
  const [numPack, setNumPack] = useState(null);
  const [weight, setWeight] = useState(null);
  const [productName, setProductName] = useState('');
  const [productCategory, setProductCategory] = useState({ code: '', name_ru: ''});
  const [parcelPickup, setParcelPickup] = useState({});
  const [parcelDelivery, setParcelDelivery] = useState({});
  const [paidWeight, setPaidWeight] = useState(null);
  const [showMessage, setShowMessage] = useState(false);
  const [selectedOrg, setSelectedOrg] = useState({ id: null, name: '' })
  const [showAllSolution, setShowAllSolution] = useState(false);
  
  const requiredFields = [
    from.kato,
    to.kato,
    cargoValue,
    numPack,
    weight,
    productName,
    productCategory.code,
    paidWeight,
    parcelPickup.code,
    parcelDelivery.code,
    selectedOrg.id
  ];

  const [requestId, setRequestId] = useState(null);
  const [result, setResult] = useState([]);
  const [detailDtos, setDetailDtos] = useState([]);
  const [loading, setLoading] = useState(false);

  const [radio, setRadio] = useState({psName: '', serviceType: '', postal: ''});
  const [fromOptions, setFromOptions] = useState([]);
  const [toOptions, setToOptions] = useState([]);
  const [productCategoryOptions, setProductCategoryOptions] = useState([]);
  const [extraServices, setExtraServices] = useState([]);

  useEffect(() => {
    props.onLoad(agent.Organizations.all());
  }, []);

  function resetFields() {
    setFrom({ kato: '' });
    setTo({ kato: '' });
    setCargoValue(null);
    setNumPack(null);
    setWeight(null);
    setProductName("");
    setProductCategory({ code: '', name_ru: ''});
    setParcelPickup({});
    setPaidWeight(null);
    setShowMessage(false);
    setRequestId(null);
    setResult([]);
    setDetailDtos([]);
    setRadio({psName: '', serviceType: '', postal: ''});
  };

  const changeBetween = (e) => {
    e.preventDefault();
    const temp = from;
    setFrom(to);
    setTo(temp);
  };

  const sendCalculatorForm = (e) => {
    e.preventDefault();

    if (requiredFields.every(field => field)) {
      setShowMessage(false);
      setLoading(true);
    } else {
      setShowMessage(true);
      return false;
    }

    agent.Calculator.calculate({
      from: from.kato,  
      to: to.kato, 
      cargoValue:  cargoValue|| 0, 
      numPack, 
      paidWeight, 
      weight,
      productCategoryCode: productCategory.code, 
      productName, 
      deliveryFromAddress: parcelPickup.code === 1,
      deliveryToAddress: parcelDelivery.code === 1,
      withPrp: extraServices?.includes(ESPRZ),
      withPrd: extraServices?.includes(ESPRD),
    }, selectedOrg.id).then(response => {
      setRequestId(response.requestId);
      setResult(response.offers);
      setDetailDtos(response.calcDetailDtos);
    }).catch(error => {
      console.log('что-то пошло не так', error)
    }).finally(() => {
      setLoading(false);
    })
  };

  const onChangeFrom = (value) => {
    agent.Kato.search(value).then(response => {
      setFromOptions(response);
    }).finally(() => console.log('finally'))
  };

  const onChangeTo = (value) => {
    agent.Kato.search(value).then(response => {
      setToOptions(response);
    }).finally(() => console.log('finally'))
  };

  const onChangeProductCategories = (value) => {
    agent.ProductCategory.all(value, '', 0, 50).then(response => {
      setProductCategoryOptions(response.content);
    }).finally(() => console.log('finally'))
  };

  useEffect(() => {
    setRequestId(null);
    setResult([]);
    setDetailDtos([]);
    setRadio({psName: '', serviceType: '', postal: ''});
    setShowAllSolution(false);
  }, [selectedOrg]);

  const handleExtraServices = (e) => {
    let selectedCities = [...extraServices];
    
    if (e.checked)
        selectedCities.push(e.value);
    else
        selectedCities.splice(selectedCities.indexOf(e.value), 1);

    setExtraServices(selectedCities);
  }

  return (
    <div className={styles.container}>
      <div className={styles.box}>
        <h2 className={styles.title}>Калькулятор</h2>
        {showMessage &&
          <h4 className={styles.message}>Заполните все поля</h4>
        }

        <form id="calculatorForm" onSubmit={sendCalculatorForm} className={styles.form}>
          <div className={styles.destination}>
            <div className={styles.where}>
              <SearchSelect placeholder="Откуда" name="from"
                            state={from}
                            setState={setFrom}
                            options={fromOptions}
                            optionValue={"kato"}
                            onChange={onChangeFrom}
                            optionText={"nameRu"}
                            highlightRequired={showMessage && !from.kato}/>
              <br/>
              <span>Като: {from.kato}</span>
            </div>
            {/*<div>*/}
            {/*  <Button icon="pi pi-refresh" className="p-button-rounded p-button-text" onClick={(e) => changeBetween(e)}/>*/}
            {/*</div>*/}
            <div className={styles.where}>
              <SearchSelect placeholder="Куда" name="to"
                            state={to}
                            setState={setTo}
                            options={toOptions}
                            optionValue={"kato"}
                            onChange={onChangeTo}
                            optionText={"nameRu"}
                            highlightRequired={showMessage && !to.kato}/>
              <br/>
              <span>Като: {to.kato}</span>
            </div>
          </div>
          <div className={styles.destination}>
            <div className={styles.where}>
              <SearchSelect placeholder="Категория товара"
                            name="productCategory"
                            state={productCategory}
                            setState={setProductCategory}
                            options={productCategoryOptions}
                            optionValue={"code"}
                            onChange={onChangeProductCategories}
                            optionText={"name_ru"}
                            highlightRequired={showMessage && !productCategory.code}/>
            </div>

            <div className={styles.where}>
              <div className={styles.pickup}>
                <SearchSelect placeholder="Забор"
                              name="parcelPickup"
                              state={parcelPickup}
                              setState={setParcelPickup}
                              options={parcelPickupOptions}
                              optionValue={"code"}
                              onChange={() => {}}
                              optionText={"name_ru"}
                              highlightRequired={showMessage && !parcelPickup?.value}/>

                <SearchSelect placeholder="Доставка"
                              name="parcelDelivery"
                              // state={parcelDeliveryOptions[0]} // works with defaultValue
                              state={parcelDelivery}
                              setState={setParcelDelivery}
                              options={parcelDeliveryOptions}
                              optionValue={"code"}
                              onChange={() => {}}
                              optionText={"name_ru"}
                              // isDisabled={true}
                              // defaultValue={parcelDeliveryOptions[0]}
                              highlightRequired={showMessage && !parcelDelivery?.value}/>
              </div>
            </div>
          </div>

          <div className={styles.inputs}>
            <SearchSelect placeholder="Ключ"
                          name="calcKey"
                          state={selectedOrg}
                          setState={setSelectedOrg}
                          options={props?.orgs}
                          optionValue={"id"}
                          onChange={() => {}}
                          optionText={"name"}
                          highlightRequired={showMessage && !selectedOrg?.value} />
            <div className={styles.input}>
              <InputNumber placeholder="Количество мест" name="numPack"
                          onValueChange={(e) => setNumPack(e.value)}
                          inputStyle={{ borderColor: showMessage && !numPack ? "red" : "#ccc" }} />
            </div>
            <div className={styles.input}>
              <InputNumber placeholder="Платный вес, кг" name="paidWeight"
                          minFractionDigits={2} maxFracionDigits={2}
                          onValueChange={(e) => setPaidWeight(e.value)}
                          inputStyle={{ borderColor: showMessage && !paidWeight ? "red" : "#ccc" }} />
            </div>
            <div className={styles.input}>
              <InputNumber placeholder="Физ вес, кг" name="weight"
                           minFractionDigits={2} maxFracionDigits={2}
                           onValueChange={(e) => setWeight(e.value)}
                           inputStyle={{ borderColor: showMessage && !weight ? "red" : "#ccc" }} />
            </div>
            <div className={styles.input}>
              <InputText placeholder="Наименование товара" name="productName"
                         onChange={(e) => setProductName(e.target.value)}
                         style={{ borderColor: showMessage && !productName ? "red" : "#ccc" }} />
            </div>
            <div className={styles.input}>
              <InputNumber placeholder="Стоимость товара" name="cargoValue"
                          onValueChange={(e) => setCargoValue(e.value)} 
                          inputStyle={{ borderColor: showMessage && !cargoValue ? "red" : "#ccc" }} />
            </div>
          </div>

          <div className={styles.checkboxes}>
            <div className="p-field-checkbox" data-pr-tooltip="Включить в стоимость оплату за разгрузку товара грузчиками">
                <Checkbox inputId={ESPRD} name="service" value={ESPRD} onChange={handleExtraServices} checked={extraServices.indexOf(ESPRD) !== -1} />
                <label htmlFor={ESPRD}>Услуга разгрузки при получении</label>
            </div>
            <div className="p-field-checkbox" data-pr-tooltip="Включить в стоимость оплату за погрузку товара грузчиками">
                <Checkbox inputId={ESPRZ} name="service" value={ESPRZ} onChange={handleExtraServices} checked={extraServices.indexOf(ESPRZ) !== -1} />
                <label htmlFor={ESPRZ}>Услуга погрузки при отправлении</label>
            </div>
          </div>

          <div className={styles.buttons}>
            <div className={styles.button}>
              <Button type="reset" onClick={resetFields}
                label="Сбросить" className="p-button-warning" />
            </div>
            <div className={styles.button}>
              <Button loading={loading} type="submit" label="Рассчитать" />
            </div>
          </div>
        </form>
      </div>

      {result.length > 0 && (
        <>
          <h2 className={styles.resultTitle}>Итоги расчета</h2>

          <div className={styles.result}>
            <DataTable value={result || []}
                        className="p-datatable-customers" dataKey="id" rowHover
                        emptyMessage="Данные не найдены">

              <Column columnKey="psName" field="psName"
                      header="Грузоперевозчик" body={(rowData) => psNameBodyTemplate(rowData, radio, setRadio)} />
              <Column columnKey="deliveryTime" field="deliveryTime"
                      header="Время доставки" body={deliveryTimeBodyTemplate} />
              <Column columnKey="serviceType" field="serviceType"
                      header="Тариф" body={serviceTypeBodyTemplate} />
              <Column columnKey="psPrice" field="psPrice"
                      header="Цена грузоперевозчика" body={psPriceBodyTemplate} />
              <Column columnKey="price" field="price"
                      header="Цена" body={priceBodyTemplate} />

            </DataTable>
          </div>

          <>
            <div onClick={() => setShowAllSolution(!showAllSolution)} className={styles.subTitle}>
              <h3>Показать все расчеты</h3>
              {showAllSolution ? <i className="pi pi-angle-up"></i> : <i className="pi pi-angle-down"></i>}
            </div>

            {showAllSolution ?
              <div className={styles['all-result']}>
                <DataTable value={detailDtos || []}
                            className="p-datatable-customers" dataKey="id" rowHover
                            emptyMessage="Данные не найдены">

                  <Column columnKey="carriers" field="carriers" header="Грузоперевозчик" />
                  <Column columnKey="daysCnt" field="daysCnt" header="Время доставки" />
                  <Column columnKey="serviceTypes" field="serviceTypes" header="Тариф" />
                  <Column columnKey="prpDiscountMid" field="prpDiscountMid" header="ПРЗ на МГ (себестоимость)" />
                  <Column columnKey="prdDiscountMid" field="prdDiscountMid" header="ПРД на МГ (себестоимость)" />
                  <Column columnKey="city" field="city" header="Промежуточный город" />                  
                  <Column columnKey="postalDiscountAmountMiddle" field="postalDiscountAmountMiddle" header="Цена ГП (МГ)" />
                  <Column columnKey="postalDiscountAmountLast" field="postalDiscountAmountLast" header="Цена ГП (ЧГП)" />
                  <Column columnKey="prpDiscountLast" field="prpDiscountLast" header="ПРЗ на ЧГП (себестоимость)" />
                  <Column columnKey="prdDiscountLast" field="prdDiscountLast" header="ПРД на ЧГП (себестоимость)" />
                  <Column columnKey="totalAmount" field="totalAmount" header="Цена" />

                </DataTable>
              </div> : null
            }
          </>
          {radio.postal && (
            <Form result={result || []}
                  from={from}
                  to={to}
                  cargoValue={cargoValue}
                  numPack={numPack}
                  weight={weight}
                  productName={productName}
                  productCategory={productCategory}
                  paidWeight={paidWeight}
                  requestId={requestId}
                  radio={radio}
                  orgId={selectedOrg.id} />
          )}
        </>
      )}

      <Tooltip target=".p-field-checkbox"/>
    </div>
  )
}

const psNameBodyTemplate = (rowData, radio, setRadio) => {
  return (
    <>
      <RadioButton value={rowData.psName + ' ' + rowData.serviceType} name="psNameRadio"
                   onChange={(e) => setRadio(rowData)}
                   checked={radio.psName === rowData.psName && radio.serviceType === rowData.serviceType} />
      <span>{rowData.psName}</span>
    </>
  );
}

const deliveryTimeBodyTemplate = (rowData) => {
  return (
    <>
      <span>{rowData.deliveryTime} дней</span>
    </>
  );
}
const serviceTypeBodyTemplate = (rowData) => {
  return (
    <>
      <span>{rowData.serviceType}</span>
    </>
  );
}
const psPriceBodyTemplate = (rowData) => {
  return (
    <>
      <span>{rowData.psPrice} тенге</span>
    </>
  );
}
const priceBodyTemplate = (rowData) => {
  return (
    <>
      <span>{rowData.price} тенге</span>
    </>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(Calculator);
