import React from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";

export const RemoveZonesConfirmDialog = ({ removeConfirmBundle }) => {
  const { visible, setVisible, selected, setSelected, onRemoveSubmit, isSendLoading } = removeConfirmBundle;

  const onHide = () => { setVisible(false); setSelected(null) };

  const renderFooter = () => {
    return (
      <div>
        <Button label="Отменить" icon="pi pi-times" type="reset" onClick={onHide} className="p-button-text" />
        <Button loading={isSendLoading} label="Подтвердить" icon="pi pi-check" type="button" onClick={onRemoveSubmit} autoFocus />
      </div>
    );
  }

  return (
    <Dialog header="Удаление выбранной тарифной зоны" visible={visible} style={{ width: '50vw' }} 
            footer={renderFooter()} onHide={onHide}
    >
      <h1 style={{marginTop: 0}}>Вы уверены, что хотите удалить выбранную тарифную зону?</h1>
      <ul style={{listStyle: "outside", fontSize: "20px"}}>
        <li style={{marginBottom: "10px", color: "#D32F2F"}}>
          {selected && selected.zoneName}
        </li>
      </ul>
    </Dialog>
  )
}