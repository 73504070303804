import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import agent from "../../../agent";


const DialogConfirm = ({
  viewDialog,
  hideDialog,
  handleConfirm,
  showMessage,
  deleteTerminal,
  cities
}) => {
  
  const handleDelete = async () => {
    try {
      console.log(deleteTerminal)
      await agent.Terminals.deleteTerminal(deleteTerminal?.id);
      showMessage('success', 'Удаление терминала', 'Терминал успешно удален');
      handleConfirm();
    } catch (err) {
      hideDialog();
      showMessage('error', 'Ошибка', 'Терминал не удален');
    }
  };

  const dialogFooter = () => {
    return (
      <div>
        <Button label="Отменить" icon="pi pi-times" onClick={hideDialog} className="p-button-text" />
        <Button label="Удалить" icon="pi pi-check" onClick={handleDelete} autoFocus />
      </div>
    );
  };

  return (
    <Dialog
      header={"Удаление терминала"} 
      visible={viewDialog} 
      style={{ width: '50vw' }} 
      footer={dialogFooter}
      onHide={hideDialog}
    >
      <div>Удалить терминал в {cities?.find((c) => c?.kato === deleteTerminal?.kato)?.name || ''}, {deleteTerminal?.address || ''}?</div>  
    </Dialog>
  );
};

export default DialogConfirm;