import React, {useEffect, useRef, useState} from "react";
import { useDispatch, useSelector } from "react-redux";
import 'primeflex/primeflex.css';
import { AutoComplete } from 'primereact/autocomplete';
import { Fieldset } from "primereact/fieldset";
import { Button } from "primereact/button";
import {Dropdown} from "primereact/dropdown";
import {DataTable} from "primereact/datatable";
import { Column } from "primereact/column";
import { Paginator } from "primereact/paginator";
import { CALC_TARIFF_POSTAL_SERVICES_LOAD, SET_STREET_ABBR } from "../../constants/actionTypes";
import agent from "../../agent";
import DialogEditTerminal from "./components/DialogEditTerminal";
import { Toast } from "primereact/toast";
import DialogConfirm from "./components/DialogConfirm";
import SearchSelect from "../Calculator/SearchSelect";

const initialFilters = {
  city: {kato: ''},
  cargo: null,
};

const initialPageConfig = {
  page: 1,
  pageSize: 10,
  totalElements: 0
};

export function throttle() {
  let isThrottled = false;
  return function(func, delay, throtling = true) {
    if (!throtling) {
      func();
    } else if (!isThrottled) {
      isThrottled = true;
      setTimeout(() => {
        func();
        isThrottled = false;
      }, delay);
    }
  };
};

const searchApi = throttle();

const dialogs = ['add-edit', 'confirm-delete'];

const Terminals = () => {
  const dispatch = useDispatch();
  const tableRef = useRef(null);
  const toast = useRef(null);
  const [viewDialog, setViewDialog] = useState('');
  const {postalServices} = useSelector((state) => state?.calcTariff);
  const {streetAbbrOptions} = useSelector((state) => state?.terminals);
  const [cityOptions, setCityOptions] = useState([]);
  const [cities, setCities] = useState([]);
  const [selectedTerminal, setSelectedTerminal] = useState(null);
  const [deleteTerminal, setDeleteTerminal] = useState(null);
  const [filters, setFilters] = useState(initialFilters);
  const [pageConfig, setPageConfig] = useState(initialPageConfig);
  const [terminals, setTerminals] = useState([]);

  useEffect(() => {
    fetchPostalServices();
    fetchStreetAbbr();
    fetchCities('all', '');
    fetchTerminals('', '');
  }, []);

  useEffect(() => {
    if (selectedTerminal) {
      setViewDialog(dialogs[0]);
    }
  }, [selectedTerminal]);

  useEffect(() => {
    if (deleteTerminal) {
      setViewDialog(dialogs[1]);
    }
  }, [deleteTerminal]);

  const fetchTerminals = async (id, kato, page = 0, pageSize = 10) => {
    const data = await agent.Terminals.getTerminals(id || '', kato || '', page, pageSize);
    setPageConfig({
      ...pageConfig, 
      page: data?.number,
      pageSize: data?.size, 
      totalElements: data?.totalElements
    });
    setTerminals(data?.content||[]);
  };

  const fetchStreetAbbr = async () => {
    const data = await agent.StreetAbbr.all();
    dispatch({ type: SET_STREET_ABBR, payload: data })
  };

  const fetchPostalServices = async () => {
    const postal = await agent.CalcTariff.getPostalServices();
    dispatch({ type: CALC_TARIFF_POSTAL_SERVICES_LOAD, payload: postal });
  };

  const fetchCities = async (type, value) => {
    const data = await agent.Terminals.searchCity(value);
    if (type === 'all') {
      setCities(data);
      setCityOptions(data);
    } else if (type === 'search') {
      setCityOptions(data);
    }
  };

  const showMessage = (severity, summary, detail, life = 5000, sticky = false) => {
    toast.current.show({severity, summary, detail, life, sticky });
  }

  const handleFilters = (key, value) => {
    setFilters({...filters, [key]: value});
  };

  const handlePageChange = (event) => {
    fetchTerminals(filters?.cargo, filters?.city?.kato, event.page, event.rows);
  };

  const tableBodyPostServ = (rowData) => {
    return (
      <p>{postalServices?.find((ps) => ps?.id === rowData?.postal_service_id)?.nameRu || ''}</p>
    );
  };

  const tableBodyStreetType = (rowData) => {
    return (
      <p>{streetAbbrOptions?.find((abbr) => abbr?.id === rowData?.street_abbr_id)?.nameRu || ''}</p>
    );
  };

  const tableBodyCity = (rowData) => {
    return (
      <p>{cities?.find((city) => city?.kato === rowData?.kato)?.name || ''}</p>
    );
  };

  const tableBodyButtons = (rowData) => {
    return (
      <div className="p-d-flex p-jc-center">
        <Button
          onClick={() => setSelectedTerminal(rowData)}
          className="p-button-text p-button-rounded" 
          icon="pi pi-pencil" 
        />

        <Button
          onClick={() => setDeleteTerminal(rowData)}
          className="p-button-text p-ml-3 p-button-rounded" 
          icon="pi pi-trash" 
        />
      </div>
    );
  };

  const handleFind = () => {
    fetchTerminals(filters?.cargo, filters?.city?.kato, 0, pageConfig.pageSize);
  };

  const handleClean = () => {
    setFilters(initialFilters);
    fetchTerminals('', '', 0, pageConfig.pageSize);
    setCityOptions(cities||[]);
  };

  const closeDialog = () => {
    setViewDialog('');
    if (selectedTerminal) {
      setSelectedTerminal(null);
    } else if (deleteTerminal) {
      setDeleteTerminal(null);
    }
  };

  const onDialogSuccess = () => {
    closeDialog();
    fetchTerminals(filters?.cargo, filters?.city?.kato, pageConfig.page, pageConfig.pageSize);
  };

  return(
    <div> 
      <Toast ref={toast}/>
      <DialogConfirm
        viewDialog={viewDialog === dialogs[1]}
        hideDialog={closeDialog}
        handleConfirm={onDialogSuccess}
        showMessage={showMessage}
        deleteTerminal={deleteTerminal}
        cities={cities}
      />
      <DialogEditTerminal 
        viewDialog={viewDialog === dialogs[0]}
        hideDialog={closeDialog}
        cities={cities}
        streetAbbrOptions={streetAbbrOptions}
        postalServices={postalServices}
        editTerminal={selectedTerminal}
        showMessage={showMessage}
        onSuccess={onDialogSuccess}
      />

      <div className="p-d-flex p-jc-between p-ai-center">
        <h1>Терминалы</h1>
        <Button className="p-d-block" label="Добавить Терминал" onClick={() => setViewDialog(dialogs[0])} icon="pi pi-plus" />          
      </div>
      <Fieldset legend="Header" toggleable>      
        <div className="p-fluid p-formgrid p-grid   p-mb-3">
          <div className="p-col-4 p-mr-3 p-d-flex p-flex-column">
            <label className="p-mb-1" htmlFor="city">Выберите город</label>            
            <SearchSelect
              placeholder="" 
              name="name"
              state={filters.city}
              setState={(e) => handleFilters('city', e)}
              options={cityOptions}
              optionValue={"kato"}
              onChange={(e) => fetchCities('search', e)}
              optionText={"name"}
            />
          </div>

          <div className="p-col-4 p-d-flex p-flex-column">
            <label className="p-mb-1" htmlFor="psId">Грузоперевозчик</label>
            <Dropdown inputId="psId" value={filters.cargo} 
              options={postalServices}
              onChange={(e) => handleFilters('cargo', e.value)}
              optionLabel="nameRu" 
              optionValue="id" 
              showClear
            />
          </div>
        </div>

        <div className="p-grid p-justify-end">
          <div className="p-mr-2">
            <Button className="p-button-warning" label="Сбросить" onClick={handleClean}/>
          </div>
          <div className="p-mr-2">
            <Button onClick={handleFind} label="Найти"/>
          </div>
        </div>
      </Fieldset>
      <div className="datatable-doc-demo">
        <div className="card">
          <div className="card">
            <DataTable
              ref={tableRef} 
              value={terminals}
              className="p-datatable-customers" dataKey="id" rowHover
              emptyMessage="Данные не найдены"
            >
              <Column field="postal_service_id" columnKey="postal_service_id" header="Грузоперевозчик" body={tableBodyPostServ}/>
              <Column field="kato" columnKey="kato" header="Город" body={tableBodyCity}/>
              <Column field="street" columnKey="street" header="Улица" />
              <Column field="street_abbr_id" columnKey="street_abbr_id" header="Тип улицы" body={tableBodyStreetType} />
              <Column field="house" columnKey="house" header="Дом" />
              <Column field="office" columnKey="office" header="Офис" />
              <Column field="time" columnKey="time" header="Время работы терминала" />
              <Column body={tableBodyButtons} field="id" />
            </DataTable>
          </div>
          <Paginator
            currentPageReportTemplate="Показано с {first} по {last} из {totalRecords} записей"
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            first={pageConfig.page*pageConfig.pageSize} 
            page={pageConfig.page}
            rows={pageConfig.pageSize}
            totalRecords={pageConfig.totalElements} 
            rowsPerPageOptions={[10, 25, 50]}
            onPageChange={handlePageChange}
          />
        </div>
      </div>
    </div>
  );
};

export default Terminals;