import {
    POSTAL_ROUTE_PAGE_ADD,
    POSTAL_ROUTE_PAGE_CHANGE_ROUTE,
    POSTAL_ROUTE_PAGE_LOADED,
    POSTAL_ROUTE_PAGE_UNLOADED, TASK_QUEUE_PATCH
} from "../constants/actionTypes";


export default (state = {}, action) => {
    switch (action.type) {
        case POSTAL_ROUTE_PAGE_LOADED:
            return {
                ...state,
                postalRoutes: action.payload,
            };
        case POSTAL_ROUTE_PAGE_UNLOADED:
            return {};
        case POSTAL_ROUTE_PAGE_ADD:
            return {
                ...state,
                commentErrors: action.error ? action.payload.errors : null,
                comments: action.error ?
                    null :
                    (state.comments || []).concat([action.payload.comment])
            };
        case POSTAL_ROUTE_PAGE_CHANGE_ROUTE:
            const page = state.postalRoutes;
            const index = [...page.content].findIndex(taskQueue => taskQueue.id ===  action.payload.id);
            page.content[index] = action.payload;
            return {
                ...state,
                postalRoutes: page,
            }
        default:
            return state;
    }
};
