import React, { useEffect, useState } from "react";
import { Dialog } from "primereact/dialog";
import { InputNumber } from "primereact/inputnumber";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";

export const EditWeightListDialog = props => {
  const {
    showEditDialog, 
    onEditHide, 
    onEditSubmit, 
    renderEditFooter,
    updateListData,
		removeData,
    formName,
		title,
		// table data
    listData,
  } = props;

	const [list, setList] = useState();

	useEffect(() => {
		setList(JSON.parse(JSON.stringify(listData)));
	}, [listData]);

  const weightBodyTemplate = (rowData, event) => {
    return (
			<InputNumber 
				name="until" value={Number(rowData.until)} inputStyle={{width: '100%'}}
				minFractionDigits={2} maxFracionDigits={2}
				onChange={(e) => { updateListData(setList, event.rowIndex, 'until', e.value) }} 
			/>
    )
  }

	const priceForWeightBodyTemplate = (rowData, event) => {
		return (
			<InputNumber 
				name="price" value={Number(rowData.price)} inputStyle={{width: '100%'}}
				minFractionDigits={2} maxFracionDigits={2}
				onChange={(e) => { updateListData(setList, event.rowIndex, 'price', e.value) }} 
			/>
		)
	}

	const stepBodyTemplate = (rowData, event) => {
		return (
			<InputNumber 
				name="next.step" value={rowData.next ? Number(rowData.next.step) : 0} 
				inputStyle={{width: '100%'}} minFractionDigits={2} maxFracionDigits={2}
				onChange={(e) => { updateListData(setList, event.rowIndex, 'next.step', e.value) }} 
			/>
		)
	}

	const priceForStepBodyTemplate = (rowData, event) => {
		return (
			<InputNumber 
				name="next.price" value={rowData.next ? Number(rowData.next.price) : 0} 
				inputStyle={{width: '100%'}} minFractionDigits={2} maxFractionDigits={2}
				onChange={(e) => { updateListData(setList, event.rowIndex, 'next.price', e.value) }} 
			/>
		)
	}

	const removeBodyTemplate = (rowData, event) => {
		return (
			<Button label="Удалить" onClick={() => { removeData(setList, event.rowIndex) }} 
							icon="pi pi-trash" className="p-button-text" type="button"
			/>
		)
	}

	const handleSubmit = (e) => {
		e.preventDefault();
		onEditSubmit(list, setList);
	}
	
	const handleHide = () => {
		setList(JSON.parse(JSON.stringify(listData)));
		onEditHide();
	}

  return (
    <Dialog header={title} visible={showEditDialog} 
						style={{ width: "100%", maxWidth: "800px", margin: "50px" }} 
            footer={renderEditFooter(formName, setList)} onHide={handleHide} scrollable>
			<form id={formName} onSubmit={handleSubmit}>
				<div className="card">
					<DataTable value={list}
										 className="p-datatable-customers" dataKey="id" rowHover
										 emptyMessage="Данные не найдены" scrollable scrollHeight="300px">
						<Column columnKey="until" field="until" header="Начальный вес, кг" body={weightBodyTemplate} />
						<Column columnKey="price" field="price" header="Цена за начальный вес, тг" body={priceForWeightBodyTemplate} />
						<Column columnKey="next.step" field="next.step" header="Шаг" body={stepBodyTemplate} />
						<Column columnKey="next.price" field="next.price" header="Цена за шаг, тг" body={priceForStepBodyTemplate} />
						<Column columnKey="remove" body={removeBodyTemplate} />
					</DataTable>
				</div>
			</form>
    </Dialog>
  )
}