import { connect } from "react-redux";
import { SLQ_USERS_LOAD, SLQ_USERS_UNLOAD } from "../../constants/actionTypes";
import React, {useEffect, useRef, useState} from "react";
import agent from "../../agent";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { TabPanel, TabView } from "primereact/tabview";
import { InputText } from "primereact/inputtext";
import { Fieldset } from "primereact/fieldset";
import { Button } from "primereact/button";
import { Paginator } from "primereact/paginator";
import { history } from "../../store";
import { trim } from "../../utils/util";
import { Link } from "react-router-dom";

const mapStateToProps = (state) => ({
  ...state.slqUser,
});

const mapDispatchToProps = (dispatch) => ({
  onLoad: (payload) => {
    dispatch({ type: SLQ_USERS_LOAD, payload });
  },
  onUnload: () => {
    dispatch({ type: SLQ_USERS_UNLOAD });
  },
});

const SLQUsers = (props) => {
  console.log("SLQUsers props", props);
  const legalTable = useRef();
  const personTable = useRef();
  const [first, setFirst] = useState(0);
  const [q, setQ] = useState("");
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [realm, setRealm] = useState("slq_jur");
  const [activeIndex, setActiveIndex] = useState(0);
  const [panelCollapsed, setPanelCollapsed] = useState(false);

  const submitForm = (e) => {
    e.preventDefault();
    const localQ = searchQuery({ q, page: page + 1, pageSize });
    history.push(
      `/users${localQ.length > 1 ? "?".concat(localQ.substring(1)) : ""}`
    );
    props.onLoad(agent.SlqUser.all(realm, q, page, pageSize));
  };

  const clearForm = (e) => {
    e.preventDefault();
    setRealm("slq_jur");
    setFirst(0);
    setPage(0);
    setQ("");
    props.onLoad(agent.SlqUser.all(realm, "", 0, pageSize));
  };

  const onTabChange = (index) => {
    setActiveIndex(index);
    const localRealm = index === 0 ? "slq_jur" : "slq_phys";
    setRealm(localRealm);
    props.onLoad(agent.SlqUser.all(localRealm, q, page, pageSize));
  };

  const formatDate = (val) => {
    if (val) {
      return val.toString().padStart(2, "0");
    }
    return val;
  };

  const searchQuery = (state) => {
    let q = "";
    for (let [key, value] of Object.entries(state)) {
      value = trim(value);
      if (value) {
        if (Array.isArray(value) && !key.includes("status")) {
          let from = new Date(value[0]);
          let to = value[1] ? new Date(value[1]) : new Date();
          value = `${formatDate(from.getDate())}.${formatDate(
            from.getMonth() + 1
          )}.${from.getFullYear()}-${formatDate(to.getDate())}.${formatDate(
            to.getMonth() + 1
          )}.${to.getFullYear()}`;
        }
        q = !q.includes(`${key}=${value}`)
          ? (q || "&") + `${key}=${value}&`
          : "";
      }
    }
    if (q && q.length > 1) {
      q = q.substring(0, q.length - 1);
    }
    return q || "";
  };

  const onPageChange = (event) => {
    setPage(event.page);
    setPageSize(event.rows);
    setFirst(event.first);
    props.onLoad(agent.SlqUser.all(realm, q, event.page, event.rows));
    const localQ = searchQuery({
      q,
      page: event.page + 1,
      pageSize: event.rows,
    });
    history.push(
      `/users${localQ.length > 1 ? "?".concat(localQ.substring(1)) : ""}`
    );
  };

  const createTimeBodyTemplate = (rowData) => {
    const { createdTimestamp } = rowData;
    const fromSecondsToDate = (seconds) => {
      return new Date(seconds).toLocaleString().split(",")[0];
    };
    const date = fromSecondsToDate(createdTimestamp);

    return (
      <div>
        <span>{date}</span>
      </div>
    );
  };
  const linkBodyTemplate = (rowData) => {
    return (
      <div>
        <Link to={`/user/${rowData.id}`} className="order__link">
          {rowData.id}
        </Link>
        <Button
          onClick={() => navigator.clipboard.writeText(rowData.id)}
          className="p-button-secondary p-button-text"
        >
          <i className="pi pi-copy" style={{ paddingLeft: "2px" }} />
        </Button>
      </div>
    );
  };

  const formatedTimestamp = (rowData) => {
    const date = rowData.createdTimestamp ? new Date(rowData.createdTimestamp) : '';
    const format = (date) => {
        if (date) {
            return `${(date.getDate()+ 1).toString().padStart(2, '0')}.${(date.getMonth() + 1).toString().padStart(2, '0')}.${date.getFullYear()}`;
        }
        return '';
    }
    return (
        <React.Fragment>
             <span style={{verticalAlign: 'middle', marginLeft: '.5em'}}>
                { format(date) }
            </span>
        </React.Fragment>
    )
  }

  useEffect(() => {
    props.onLoad(agent.SlqUser.all(realm, q, page, pageSize));

    return function cleanup() {
      props.onUnload();
    };
  }, []);

  return (
    <div>
      <Fieldset
        legend="Поиск"
        toggleable
        collapsed={panelCollapsed}
        onToggle={(e) => setPanelCollapsed(e.value)}
      >
        <form onSubmit={(e) => submitForm(e)}>
          <div className="p-fluid p-formgrid p-grid">
            <div className="p-field p-col-6 p-md-3 hiddenInput">
              <label htmlFor="orderNum">
                Телефон/ФИО/БИН/Наименование компании
              </label>
              <InputText
                id="orderNum"
                value={q}
                onChange={(e) => setQ(e.target.value)}
              />
            </div>
          </div>
          <div className="p-grid p-justify-end">
            <div className="p-mr-2">
              <Button type="submit" label="Найти" autoFocus />
            </div>
            <div className="p-mr-2">
              <Button
                className="p-button-warning"
                label="Сбросить"
                onClick={(e) => clearForm(e)}
              />
            </div>
          </div>
        </form>
      </Fieldset>
      <TabView
        activeIndex={activeIndex}
        onTabChange={(e) => onTabChange(e.index)}
      >
        <TabPanel header="Юр лица">
          <div className="card">
            <DataTable
              ref={legalTable}
              value={(props.slqUsers || { data: [] }).data}
              className="p-datatable-customers"
              dataKey="id"
              rowHover
              emptyMessage="Данные не найдены"
              scrollable
            >
              {/*<Column body={actionBodyTemplate} headerStyle={{width: '8em', textAlign: 'center'}} bodyStyle={{textAlign: 'center', overflow: 'visible'}} />*/}
              <Column
                headerStyle={{ width: "200px" }}
                columnKey="id"
                field="id"
                header="ID"
                body={linkBodyTemplate}
                sortable
              />
              <Column
                headerStyle={{ width: "200px" }}
                columnKey="lastName"
                field="lastName"
                header="Фамилия"
                sortable
              />
              <Column
                headerStyle={{ width: "200px" }}
                columnKey="firstName"
                field="firstName"
                header="Имя"
                sortable
              />
              <Column
                headerStyle={{ width: "200px" }}
                columnKey="username"
                field="username"
                header="Номер телефона"
                sortable
              />
              <Column
                headerStyle={{ width: "200px" }}
                columnKey="bin"
                field="bin"
                header="БИН"
                sortable
              />
              <Column
                headerStyle={{ width: "200px" }}
                columnKey="companyName"
                field="companyName"
                header="Наименование компании"
                sortable
              />
              <Column
                headerStyle={{ width: "200px" }}
                columnKey="createdTimestamp"
                field="createdTimestamp"
                header="Дата регистрации"
                body={createTimeBodyTemplate}
                sortable
              />
            </DataTable>
            <Paginator
              currentPageReportTemplate="Показано с {first} по {last} из {totalRecords} записей"
              paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
              first={page}
              rows={pageSize}
              totalRecords={(props.slqUsers || { totalItems: 0 }).totalItems}
              rowsPerPageOptions={[10, 25, 50, "все"]}
              onPageChange={onPageChange}
            />
          </div>
        </TabPanel>
        <TabPanel header="Физ лица">
          <div className="card">
            <DataTable
              ref={personTable}
              value={(props.slqUsers || { data: [] }).data}
              className="p-datatable-customers"
              dataKey="id"
              rowHover
              emptyMessage="Данные не найдены"
              scrollable
            >
              <Column
                headerStyle={{ width: "200px" }}
                columnKey="id"
                field="id"
                header="ID"
                body={linkBodyTemplate}
                sortable
              />
              <Column
                headerStyle={{ width: "200px" }}
                columnKey="lastName"
                field="lastName"
                header="Фамилия"
                sortable
              />
              <Column
                headerStyle={{ width: "200px" }}
                columnKey="firstName"
                field="firstName"
                header="Имя"
                sortable
              />
              <Column
                headerStyle={{ width: "200px" }}
                columnKey="username"
                field="username"
                header="Номер телефона"
                sortable
              />
              <Column
                headerStyle={{ width: "200px" }}
                columnKey="createdTimestamp"
                field="createdTimestamp"
                header="Дата регистрации"
                body={createTimeBodyTemplate}
                sortable
              />
            </DataTable>
            <Paginator
              currentPageReportTemplate="Показано с {first} по {last} из {totalRecords} записей"
              paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
              first={first}
              rows={pageSize}
              totalRecords={(props.slqUsers || { totalItems: 0 }).totalItems}
              rowsPerPageOptions={[1, 10, 25, 50, "все"]}
              onPageChange={onPageChange}
            />
          </div>
        </TabPanel>
      </TabView>
    </div>
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SLQUsers);
