import {OFFER_LOADED, OFFER_UNLOADED} from "../constants/actionTypes";

const defaultState = {
    offers: []
};

export default (state = defaultState, action) => {
    switch (action.type) {
        case OFFER_LOADED:
            return {
                ...state,
                offers: action.payload,
            }
        case OFFER_UNLOADED:
            return defaultState;
        default:
            return state;
    }
};
