import React from "react";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { connect } from "react-redux";
import { FileUpload } from 'primereact/fileupload';
import { Toast } from "primereact/toast";
import { CALC_TARIFF_PS_ZONES_LOAD, CALC_TARIFF_PS_ZONES_UNLOAD, CALC_TARIFF_PS_ZONE_KATO_LIST_LOAD,
  CALC_TARIFF_PS_ZONE_KATO_LIST_UNLOAD, CALC_TARIFF_WEIGHT_LIST_LOAD, CALC_TARIFF_TAWAY_LIST_LOAD,
  CALC_TARIFF_WEIGHT_LIST_UNLOAD, CALC_DEDUCTION_TT_LIST_LOAD } from "../../../constants/actionTypes";
import agent from "../../../agent";
import { EditZoneNameDialog } from "./EditZoneNameDialog";
import { ZoneInfoTable } from "./ZoneInfoTable";
import { RemoveKatoConfirmDialog } from "./RemoveKatoConfirmDialog";
import { NewKatoDialog } from "./NewKatoDialog";
import CalcRules from "./CalcRules";

const mapStateToProps = state => ({
  calcTariff: state.calcTariff
});

const mapDispatchToProps = dispatch => ({
  onLoad: (psZoneKatoListPayload, weightListPayload, psZonesPayload) => {
      dispatch({ type: CALC_TARIFF_PS_ZONE_KATO_LIST_LOAD, payload: psZoneKatoListPayload }),
      dispatch({ type: CALC_TARIFF_WEIGHT_LIST_LOAD, payload: weightListPayload }),
      dispatch({ type: CALC_TARIFF_PS_ZONES_LOAD, payload: psZonesPayload })
  },
  onCsvFileUpload: (psZoneKatoListPayload) => {
      dispatch({ type: CALC_TARIFF_PS_ZONE_KATO_LIST_LOAD, payload: psZoneKatoListPayload })
  },
  onTerminalAwayListEdited: (terminalAwayPayload) => {
    dispatch({ type: CALC_TARIFF_TAWAY_LIST_LOAD, payload: terminalAwayPayload })
  },
  onDeductionTTEdited: (deductionTTPayload) => {
    dispatch({ type: CALC_DEDUCTION_TT_LIST_LOAD, payload: deductionTTPayload })
  },
  onWeightListEdited: (weightListPayload) => {
      dispatch({ type: CALC_TARIFF_WEIGHT_LIST_LOAD, payload: weightListPayload })
  },
  onZoneNameEdited: (psZonesPayload) => {
      dispatch({ type: CALC_TARIFF_PS_ZONES_LOAD, payload: psZonesPayload })
  },
  onNewKatoAdded: (psZoneKatoListPayload) => {
      dispatch({ type: CALC_TARIFF_PS_ZONE_KATO_LIST_LOAD, payload: psZoneKatoListPayload })
  },
  onKatoDeleted: (psZoneKatoListPayload) => {
      dispatch({ type: CALC_TARIFF_PS_ZONE_KATO_LIST_LOAD, payload: psZoneKatoListPayload })
  },
  onUnload: () => {
      dispatch({ type: CALC_TARIFF_PS_ZONE_KATO_LIST_UNLOAD }),
      dispatch({ type: CALC_TARIFF_WEIGHT_LIST_UNLOAD }),
      dispatch({ type: CALC_TARIFF_PS_ZONES_UNLOAD })
  }
});

const CalcTariff = props => {
  const toastRef = React.useRef(null);
  const showMessage = (severity, summary, detail, life = 7000, sticky = false) => {
    toastRef.current.show({severity, summary, detail, life, sticky});
  }

  const tariffId = props.match.params.tid;
  const tariffPsZoneKatoListTable = React.useRef();
  const tariffWeightListTable = React.useRef();
  const tariffTerminalAwayListTable = React.useRef();
  const tariffDeductionTTListTable = React.useRef(); 
  const [isSendLoading, setSendLoading] = React.useState(false)
  const reduxPsZones = props.calcTariff.psZones; // redux
  const [psZoneInfo, setPsZoneInfo] = React.useState(
    reduxPsZones && reduxPsZones.find(zone => zone.id == tariffId));
    
  /* edit zone name */
    const [editZoneNameDialog, setEditZoneNameDialog] = React.useState(false);
    const onEditZoneNameShow = () => { setEditZoneNameDialog(true) };

    const initialZoneName = psZoneInfo ? psZoneInfo.zoneName : ""; // redux
    const [newZoneName, setNewZoneName] = React.useState(initialZoneName);

    const onEditZoneNameSubmit = (e) => {
      e.preventDefault();

      agent.CalcTariff.editZoneName(tariffId, newZoneName)
        .then(res => {
          showMessage('success', 'Успешно', 'Наименование тарифной зоны отредактировано');
          props.onZoneNameEdited(agent.CalcTariff.getPsZones()); // updating zone info
        })
        .catch(err => {
          showMessage('error', 'Ошибка при редактировании наименования тарифной зоны', `${err}`);
        })
        .finally(() => { setEditZoneNameDialog(false) });
    };

    React.useEffect(() => {
      !newZoneName && setNewZoneName(initialZoneName)
    }, [initialZoneName]);

    const zoneNameBundle = {
      visible: editZoneNameDialog, setVisible: setEditZoneNameDialog,
      initial: initialZoneName, value: newZoneName, setValue: setNewZoneName,
      onSubmit: onEditZoneNameSubmit
    };
  /* /edit zone name */

  {/* new kato */}
    const [newKatoDialog, setNewKatoDialog] = React.useState(false);
    const onNewKatoShow = () => { setNewKatoDialog(true) };

    const initialKato = { katoFrom: "", katoTo: "", days: null };
    const [newKato, setNewKato] = React.useState(initialKato);
    const handleChangeKato = (name, value) => {
      setNewKato({ ...newKato, [name]: value });
    }

    const onNewKatoSubmit = (e) => {
      e.preventDefault();
      if ([newKato.katoFrom, newKato.katoTo, newKato.days].every(item => item)) {
        agent.CalcTariff.addNewKato(tariffId, newKato)
          .then(res => {
            setNewKato(initialKato);
            showMessage('success', 'Успешно', 'Новое направление КАТО добавлено. Не забудьте сохранить правила расчета!');
            props.onNewKatoAdded(agent.CalcTariff.getPsZoneKatoList(tariffId)); // update;
          })
          .catch(err => {
            if (err?.response?.body?.message) {
              showMessage('error', 'Ошибка при добавлении нового направления КАТО', err.response.body.message);
            }
            else {
              showMessage('error', 'Ошибка при добавлении нового направления КАТО', `${err}`);
            }
          })
          .finally(() => { setNewKatoDialog(false) });
      }
      else {
        showMessage('warn', 'Ошибка при создании тарифа', 'Все поля обязательны к заполнению');
      }
    };

    React.useEffect(() => {
      !newKato && setNewKato(initialKato)
    }, [initialKato]);

    const newKatoBundle = {
      visible: newKatoDialog, setVisible: setNewKatoDialog,
      initial: initialKato, value: newKato, setValue: setNewKato,
      handleChange: handleChangeKato, onSubmit: onNewKatoSubmit
    };
  {/* /new kato */}

  {/* remove kato */}
    const [removeConfirmDialog, setRemoveConfirmDialog] = React.useState(false);
    const onShowRemoveConfirm = () => setRemoveConfirmDialog(true);
    const [selectedKato, setSelectedKato] = React.useState(null);

    const onRemoveSubmit = () => {
      const obj = {
        katoFrom: selectedKato.katoFrom.code,
        katoTo: selectedKato.katoTo.code,
        days: selectedKato.days
      };

      agent.CalcTariff.deactivateKato(tariffId, obj)
        .then(res => {
          setSelectedKato(null);
          showMessage('success', 'Успешно', 'Направление КАТО удалено');
          props.onKatoDeleted(agent.CalcTariff.getPsZoneKatoList(tariffId)); // update;
        })
        .catch(err => {
          showMessage('error', 'Ошибка при удалении направления КАТО', `${err}`);
        })
        .finally(() => { setRemoveConfirmDialog(false) });
    }

    const removeConfirmBundle = {
      visible: removeConfirmDialog, setVisible: setRemoveConfirmDialog,
      selected: selectedKato, setSelected: setSelectedKato,
      onRemoveSubmit
    };
  {/* /remove kato */}

  {/* edit weight list */}
  function checkWeightSubmit(list) {
    const checkWeights = (weights) => {
      const results = [];
    
      for (let i = 0; i < weights.length; i++) {
        const { until, price, next } = weights[i];
    
        const isUntil = until > 0;
        const isPrice = price >= 0;
    
        let isNext;
        if (next) {
          const isNextStep = next.step >= 0;
          const isNextPrice = next.price >= 0;
    
          if (isNextStep && isNextPrice) {
            isNext = true;
          } else {
            isNext = false;
          }
        } else {
          isNext = false;
        }
    
        results.push(isUntil, isPrice, isNext);
      }
    
      return results.every(result => result);
    };

    if (!checkWeights(list)) {
      showMessage(
        'warn', 'Ошибка при редактировании правил расчета', 
        'Начальный вес обязателен к заполнению (значение должно быть больше 0.00)'
      );
      return;
    }
  }

  function onWeightSubmit({
    list, 
    setList, 
    setLoading,
    onEditModalHide
  }) {
    agent.CalcTariff.editWeightList(tariffId, list)
    .then(res => {
      if (res?.status === 202) {
        showMessage('info', 'Ваш запрос получен и находится в процессе обработки. Пожалуйста, подождите результатов.', res?.message ? res?.message : '', 0, true);            
        return;
      }
      showMessage('success', 'Успешно', 'Правила расчета отредактированы');
      props.onWeightListEdited(agent.CalcTariff.getWeightList(tariffId));
    })
    .catch(err => {
      setList(weightListData);
      showMessage('error', 'Ошибка при редактировании правил расчета', `${(err?.response?.body || {message: ''}).message}`);
    })
    .finally(() => {
      onEditModalHide();
      setLoading(false);
    });
  }
  {/* /edit weight list */}

  {/* edit terminal away list */}
    const checkTerminalAwayList = (listData) => {
      const results = [];
    
      for (let i = 0; i < listData.length; i++) {
        const { until, price, next } = listData[i];
        const isUntil = +until > 0;
        const isPrice = +price > 0;
        const isNext = next ? ((+next.step)>0 && (+next.price)>0) || ((+next.step)===0 && (+next.price)===0) : false;

        results.push(isUntil, isPrice, isNext);
      }
      return results.every(result => result)
    };

    const checkMaxWeightNextValue = (listData) => {
      if (listData.length === 0) return true;
      const {until} = [...listData].sort((a, b) => (+b?.until) - (+a?.until))[0];
      return listData.filter((item) => item.until === until).some(({next}) => next?.step > 0 && next?.price > 0);
    };

    function checkTerminalSubmit(list) {
      if (!checkTerminalAwayList(list)) {
        showMessage(
          'warn', 'Ошибка при редактировании правил вычета для Т->Д и Д->Т', 
          'Значения начальный вес и цена за начальный должны быть больше 0.00. Значения шаг и цена за шаг должны быть больше 0.00 либо оба равняться 0.00'
        );
        return;
      } else if (!checkMaxWeightNextValue(list)) {
        showMessage(
          'warn', 'Ошибка при редактировании правил вычета для Т->Д и Д->Т', 
          'В поле с максимальынм весом значение шага должно превышать 0.00'
        );
        return;
      }
    };

    function onTerminalSubmit({
      list, 
      setList, 
      setLoading, 
      onEditModalHide,
      sortedListData
    }) {
      const {id, psCode} = props.calcTariff.terminalAwayList;
      const data = { id, psCode, weightValue: list };

      agent.CalcTariff.editTerminalAwayList(data)
        .then(res => {
          if (res === null) throw new Error("Введен неверный формат данных");
          showMessage('success', 'Успешно', 'Расчет правил вычета для Т->Д и Д->Т отредактирован');
          props.onTerminalAwayListEdited(res);
        })
        .catch(err => {
          setList(JSON.parse(JSON.stringify(sortedListData)));
          showMessage('error', 'Ошибка при редактировании правил вычета для Т->Д и Д->Т', `${err}`);
        })
        .finally(() => {
          onEditModalHide();
          setLoading(false);
        });
    };
  {/* edit terminal away list */}

  {/* edit deduction rulles for T->T */}
    function checkDeductionSubmit(list) {
      if (!checkTerminalAwayList(list)) {
        showMessage(
          'warn', 'Ошибка при редактировании правил вычета для Т->Т', 
          'Значения начальный вес и цена за начальный должны быть больше 0.00. Значения шаг и цена за шаг должны быть больше 0.00 либо оба равняться 0.00'
        );
        return;
      } else if (!checkMaxWeightNextValue(list)) {
        showMessage(
          'warn', 'Ошибка при редактировании правил вычета для Т->Т', 
          'В поле с максимальынм весом значение шага должно превышать 0.00'
        );
        return;
      }
    }

    function onDeductionSubmit({
      list, 
      setList, 
      sortedListData,
      setLoading, 
      onEditModalHide,
    }) {
      const {id, psCode} = props.calcTariff.deductionTTList;
      const data = { id, psCode, weightValue: list };

      agent.CalcTariff.editDeductionTTList(data)
        .then(res => {
          if (res === null) throw new Error("Введен неверный формат данных");
          showMessage('success', 'Успешно', 'Расчет правил вычета для для Т->Т отредактирован');
          props.onDeductionTTEdited(res);
        })
        .catch(err => {
          setList(JSON.parse(JSON.stringify(sortedListData)));
          showMessage('error', 'Ошибка при редактировании правил вычета для Т->Т', `${err}`);
        })
        .finally(() => {
          onEditModalHide();
          setLoading(false);
        });
    }
  {/* edit deduction rulles for T->T */}

  {/* upload csv */}
    const reduxPsZoneKatoList = props.calcTariff.psZoneKatoList;

    const fileUploadRef = React.useRef(null);
    const [csvFile, setCsvFile] = React.useState(null);

    const clearCsvFile = () => {
      setCsvFile(null);

      if (fileUploadRef.current) { fileUploadRef.current.clear() }
    }

    const handleFileSelect = (e) => {
      const file = e.files[0];
      const splittedFileName = file.name.split('.');
      const extension = splittedFileName[splittedFileName.length - 1];

      if (extension === 'csv') { setCsvFile(file) }
      else {
        clearCsvFile();

        toastRef.current.show({
          severity: 'warn',
          summary: 'Ошибка при добавлении CSV файла',
          detail: 'Добавляемый файл не является CSV файлом'
        });
      }
    }

    const handleFileUpload = (e) => {
      setSendLoading(true)
      const formDataWithCsvFile = new FormData();
      formDataWithCsvFile.append('file', csvFile);
      agent.CalcTariff.uploadPsZoneKatoList(tariffId, formDataWithCsvFile)
        .then(res => {
          if (res?.status === 202) {
            showMessage('info', 'Ваш запрос получен и находится в процессе обработки. Пожалуйста, подождите результатов.', res?.message ? res?.message : '', 0, true);            
            return;
          }
          showMessage('success', 'Успешно', 'CSV файл загружен');
          props.onCsvFileUpload(agent.CalcTariff.getPsZoneKatoList(tariffId))
        })
        .catch(err => {
          showMessage('error', 'Ошибка при загрузке CSV файла', `${(err?.response?.body || {message: ''}).message}`, 0, true);
        })
        .finally(() => {
          clearCsvFile()
          setSendLoading(false)
        });
    }
  {/* /upload csv */}

  const idBodyTemplate = (rowData, event) => <span>{event.rowIndex}</span>;

  React.useEffect(() => {
    props.onLoad(
      agent.CalcTariff.getPsZoneKatoList(tariffId),
      agent.CalcTariff.getWeightList(tariffId),
      agent.CalcTariff.getPsZones(),
    );
    
    return () => { props.onUnload() };
  }, []);

  React.useEffect(() => {
    if (psZoneInfo) {
      props.onTerminalAwayListEdited(agent.CalcTariff.getTerminalAwayList(psZoneInfo?.psTariffDto?.code));
      props.onDeductionTTEdited(agent.CalcTariff.getDeductionTTList(psZoneInfo?.psTariffDto?.code));
    }
  }, [psZoneInfo]);

  React.useEffect(() => {
    setPsZoneInfo(reduxPsZones.find(zone => zone.id == tariffId));
  }, [props.calcTariff]);

  return (
    <div>
      <Toast ref={toastRef} style={{"whiteSpace": "pre-line"}} />

      <NewKatoDialog newKatoBundle={newKatoBundle} />
      <RemoveKatoConfirmDialog removeConfirmBundle={removeConfirmBundle} />

      <EditZoneNameDialog zoneNameBundle={zoneNameBundle} />

      <div className="p-d-flex p-jc-between p-ai-center">
        <h1>Описание тарифа №{tariffId}</h1>
        <Button className="p-d-block" label="Изменить наименование зоны" icon="pi pi-pencil"
                onClick={onEditZoneNameShow} />
      </div>

      <ZoneInfoTable psZoneInfo={psZoneInfo} />

      <div className="p-d-flex" style={{flexWrap: 'wrap'}}>
        <div className="p-d-block p-mr-6" style={{flex: .9}}>
          <div className="p-d-flex p-jc-between p-ai-center">
            <h2>Выборка направлений по тарифу</h2>
            {csvFile
              ? <div className="p-d-flex">
                  <Button icon="pi pi-times" label={`Отменить`} onClick={clearCsvFile} className="p-button-text p-mr-2" />
                  <Button icon="pi pi-check" label={`Отправить ${csvFile.name}`} onClick={handleFileUpload} loading={isSendLoading}/>
                </div>
              : <FileUpload ref={fileUploadRef} chooseLabel="Выбрать CSV файл" name="testFile"
                  customUpload mode="basic" accept=".csv" onSelect={handleFileSelect}
                  uploadHandler={handleFileUpload}
                />
            }
          </div>

          <div className="p-d-flex p-jc-between p-ai-center">
            <Button label={selectedKato ? "Удалить выбранное направление КАТО" : ""}
                    icon={"pi pi-trash"} style={{minWidth: "3.2rem", transition: "all .1s"}}
                    className={selectedKato ? "p-button-danger" : "p-button-text p-button-danger"}
                    onClick={onShowRemoveConfirm} disabled={!selectedKato}
            />
            <Button className="p-button-text" icon="pi pi-plus"
                    label="Добавить направление КАТО" onClick={onNewKatoShow} />
          </div>

          <DataTable ref={tariffPsZoneKatoListTable} value={reduxPsZoneKatoList}
                     selection={selectedKato} onSelectionChange={(e) => { setSelectedKato(e.value) }}
                     className="p-datatable-customers" dataKey="id" rowHover
                     emptyMessage="Данные не найдены" scrollable>
              <Column selectionMode="single" headerStyle={{ width: '3rem' }} exportable={false} />
              <Column headerStyle={{ width: '100px' }} columnKey="id" field="id" header="ID" body={idBodyTemplate} />
              <Column columnKey="katoFrom.nameRu" field="katoFrom.nameRu" header="Откуда" />
              <Column columnKey="katoFrom.code" field="katoFrom.code" header="Откуда - КАТО" />
              <Column columnKey="katoTo.nameRu" field="katoTo.nameRu" header="Куда" />
              <Column columnKey="katoTo.code" field="katoTo.code" header="Куда - КАТО" />
              <Column columnKey="days" field="days" header="Срок доставки" />
          </DataTable>
        </div>

        <div className="p-d-block p-mr-6" style={{flex: .9}}>
          <CalcRules
            data={props.calcTariff.weightList}
            onSubmit={onWeightSubmit}
            calcTariff={props.calcTariff}
            checkSubmit={checkWeightSubmit}
            title1={'Правила расчета'}
            title2={'Редактирование правил расчета'}
          />

          {props.calcTariff.terminalAwayList && psZoneInfo && psZoneInfo.tariffType !== 'STANDARD' ?
            <CalcRules
              data={props.calcTariff.terminalAwayList?.weightValue}
              onSubmit={onTerminalSubmit}
              calcTariff={props.calcTariff.terminalAwayList}
              checkSubmit={checkTerminalSubmit}
              title1={'Правила вычета для Т-Д и Д-Т'}
              title2={'Редактирование правил вычета для Т->Д и Д->Т'}
            />
            : null
          }

          {props.calcTariff.terminalAwayList && psZoneInfo && psZoneInfo.tariffType !== 'STANDARD' ?
            <CalcRules
              data={props.calcTariff.deductionTTList?.weightValue}
              onSubmit={onDeductionSubmit}
              calcTariff={props.calcTariff.deductionTTList}
              checkSubmit={checkDeductionSubmit}
              title1={'Правила вычета для Т-Т'}
              title2={'Редактирование правил вычета для Т->Т'}
            />
            : null
          }
        </div>
      </div>
    </div>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(CalcTariff);
