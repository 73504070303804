import React from "react";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";

export const EditZoneNameDialog = ({ zoneNameBundle }) => {
  const { visible, setVisible, initial, value, setValue, onSubmit } = zoneNameBundle;

  const onChange = (e) => { setValue(e.target.value) };

  const onHide = () => { setVisible(false); setValue(initial) };
  const onCancel = () => { onHide() };

  const renderFooter = () => {
    return (
      <div>
        <Button label="Отменить" icon="pi pi-times" type="reset" onClick={onCancel} className="p-button-text" />
        <Button label="Сохранить" icon="pi pi-check" type="button" onClick={onSubmit} form="weightListEditForm" autoFocus />
      </div>
    );
  }

  return (
    <Dialog header="Изменение наименования тарифной зоны" visible={visible} style={{ width: '50vw' }} 
            footer={renderFooter()} onHide={onHide}
    >
      <InputText name="zoneName" type="text" placeholder={"Наименование зоны"} 
                 value={value} onChange={onChange} style={{width: "100%"}} 
      />
    </Dialog>
  )
}