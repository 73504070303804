import React, {useEffect, useRef, useState} from "react";
import agent from "../../agent";
import {
    CALC_TARIFF_POSTAL_SERVICES_LOAD,
    ORDER_PAGE_LOADED, POSTAL_ROUTE_PAGE_CHANGE_ROUTE,
    POSTAL_ROUTE_PAGE_LOADED,
    POSTAL_ROUTE_PAGE_UNLOADED, POSTAL_SERVICE_PAGE_LOADED, TASK_QUEUE_PATCH
} from "../../constants/actionTypes";
import {connect} from "react-redux";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {Button} from "primereact/button";
import '../Order/DataTableDemo.css'
import {Toast} from "primereact/toast";
import {Fieldset} from "primereact/fieldset";
import {Dropdown} from "primereact/dropdown";
import {ContextMenu} from "primereact/contextmenu";
import {InputText} from "primereact/inputtext";
import {Paginator} from "primereact/paginator";
import {history} from "../../store";
import {Dialog} from "primereact/dialog";
import { FileUpload } from 'primereact/fileupload';
import { RadioButton } from 'primereact/radiobutton';
import SearchSelect from "../Calculator/SearchSelect";

const mapStateToProps = state => ({
    ...state.postalRoute,
    ...state.postalService,
    ...state.calcTariff
});

const mapDispatchToProps = dispatch => ({
    onLoad: (payload, payload2, payload3) => {
        dispatch({ type: POSTAL_ROUTE_PAGE_LOADED, payload });
        dispatch({ type: POSTAL_SERVICE_PAGE_LOADED, payload: payload2 });
        dispatch({ type: CALC_TARIFF_POSTAL_SERVICES_LOAD, payload: payload3 })
    },
    onUnload: () =>
        dispatch({ type: POSTAL_ROUTE_PAGE_UNLOADED }),
    onSearch: (payload) =>
        dispatch({ type: POSTAL_ROUTE_PAGE_LOADED, payload }),
    patchPostalRoute: (payload) =>
        dispatch({ type: POSTAL_ROUTE_PAGE_CHANGE_ROUTE, payload }),
});

const modals = ['create', 'edit'];

const PostalRouteTable = ({postalRoutes, patchPostalRoute, openModal, statuses, updatePage, showMessage}) => {
    const [selectedRoute, setSelectedRoute] = useState(null);
    const dt = useRef(null);
    const cm = useRef(null);

    const menuModel = [
        {label: statuses[0].label, command: () => oneAction(selectedRoute, statuses[0].value)},
        {label: statuses[1].label, command: () => oneAction(selectedRoute, statuses[1].value)},
        {label: statuses[2].label, command: () => oneAction(selectedRoute, statuses[2].value)},
        {label: statuses[3].label, command: () => oneAction(selectedRoute, statuses[3].value)}
    ];

    const oneAction = async (route, status) => {
        try {
            await agent.PostalRoute.editRoute(route.id, status);
            updatePage();
            showMessage('success', 'Редактирование направления', `Направление успешно изменено`);
        } catch (err) {
            showMessage('error', 'Ошибка', `Направление не изменено`);
        }
    };

    const groups = (rowData) => {
        return (
            <div className='row'>
                <span style={{'paddingLeft': '5px'}}>{(rowData.kato||{}).nameRu}</span>
                <span style={{'paddingLeft': '5px'}}>{(rowData.kato||{}).kato}</span>
                <span style={{'paddingLeft': '5px'}}>{(rowData.kato||{}).group}</span>
            </div>
        );
    };

    const statusValue = (val) => {

        return (
            <div className="p-d-flex p-jc-between">
                <span className="p-mr-2">{val?.route}</span> 
                <Button
                    onClick={() => openModal(val)}
                    className="p-button-text p-button-rounded" 
                    icon="pi pi-pencil" 
                />
            </div>
        )
    };

    return (
        <div className="datatable-doc-demo">
            <ContextMenu model={menuModel} ref={cm} onHide={() => setSelectedRoute(null)}/>
            <div className="card">
                <DataTable ref={dt} value={(postalRoutes||{content: []}).content}
                        className="p-datatable-customers" dataKey="id" rowHover
                        contextMenuSelection={selectedRoute}
                        onContextMenuSelectionChange={e => setSelectedRoute(e.value)}
                        onContextMenu={e => cm.current.show(e.originalEvent)}
                        emptyMessage="Данные не найдены"
                    >
                    <Column style={{width: '6%'}} columnKey="id" field="id" header="ID"  sortable/>
                    <Column body={groups} headerStyle={{ width: '300px' }} columnKey="kato" field="kato" header="KATO" sortable/>
                    <Column columnKey="postalServiceNameRu" field="postalServiceNameRu" header="Наименование Перевозчика" sortable />
                    <Column columnKey="postalCode" field="postalCode" header="Код услуги Перевозчика" sortable />
                    <Column body={statusValue} field="route" header="Статус" sortable />
                </DataTable>
            </div>
        </div>
    );
}

const searchQuery = (state) => {

    const search = {
        ps: state.psId,
        groupCode: state.kato,
        name: state.nameRu,
        tariff: state.uslugaId
    }
    let q = null;
    for (let [key, value] of Object.entries(search)) {
        if (value) {
            q = (q || '&') + `${key}=${value}&`
        }

    }
    if (q && q.length > 1) {
        q = q.substring(0, q.length - 1);
    }
    return q||'';
}

const PostalRoute = (props) => {
    const initialPageData = {
        panelCollapsed: false,
        psId: undefined,
        routes: [],
        nameRu: '',
        tableRoutes: [],
        kato: '',
        page: 0,
        pageSize: 10,
        first: 0,
        uslugaId: null,
    }
    const initialRoute = {
        "locationName": {},
        "psId": "",
        "uslugaCode": "",
        "route": "",
      }
    const statuses = [
        {label: "Доставка и Забор", value: "TO_FROM"},
        {label: "Забор", value: "FROM"},
        {label: "Доставка", value: "TO"},
        {label: "Заблокированный", value: "BLOCKED"},
    ]
    const [pageData, setPageData] = useState(initialPageData);
    const [viewModalName, setViewModalName] = useState('');
    const [editRoute, setEditRoute] = useState(null);
    const [addRoute, setAddRoute] = useState(initialRoute);
    const [citiesOption, setCitiesOption] = useState([]);
    const [uslugasFilter, setUslugasFilter] = useState([]);
    const [uslugasAddRoute, setUslugasAddRoute] = useState([]);
    const toast = useRef(null);
    const showMessage = (severity, summary, detail, life = 7000, sticky = false) => {
        toast.current.show({severity: severity, summary: summary, detail: detail, life, sticky });
    }

    useEffect(() => {
        props.onLoad(
            agent.PostalRoute.all(pageData.page, pageData.pageSize, ''), 
            agent.PostalService.all(),
            agent.CalcTariff.getPostalServices()
        );
        return () => props.onUnload();
    }, []);

    const setUslugaList = async (setFc, id) => {
        const result = await agent.CalcTariff.getUslugaPs(id);
        setFc(result?.length ? result : []);
    }

    useEffect(() => {
        if (pageData.psId) {
            setUslugaList(setUslugasFilter, pageData.psId);
        }
    }, [pageData.psId]);
    useEffect(() => {
        if (addRoute.psId) {
            setUslugaList(setUslugasAddRoute, addRoute.psId);
        }
    }, [addRoute.psId]);

    const onPageChange = (event) => {
        setPageData({ ...pageData, page: event.page, pageSize: event.rows, first: event.first});
        const q = searchQuery(pageData);
        props.onSearch(agent.PostalRoute.all(event.page, event.rows, q));
    }

    const submitForm = () => ev => {
        ev.preventDefault();

        const q = searchQuery(pageData);
        props.onSearch(agent.PostalRoute.all(pageData.page, pageData.pageSize, q));

        history.push({
            pathname: `/postal_routes`,
            search: q});
    }

    const updatePage = () => {
        const q = searchQuery(initialPageData);
        props.onSearch(agent.PostalRoute.all(initialPageData.page, initialPageData.pageSize, q));
        setPageData(initialPageData);
    };

    const clearForm = () => ev => {
        ev.preventDefault();
        updatePage();
    };

    const handleSave = async () => {
        try {
            if (viewModalName === modals[0]) {
                await agent.PostalRoute.addRoute({
                    "productCode": addRoute.uslugaCode,
                    "route": addRoute.route,
                    "kato": addRoute?.locationName?.kato
                })
                showMessage('success', 'Создание направления', `Новое направление добавлено`);
            } else if (viewModalName === modals[1]) {
                await agent.PostalRoute.editRoute(editRoute?.id, editRoute?.route);
                showMessage('success', 'Редактирование направления', `Направление успешно изменено`);
            }
            setViewModalName('');
            updatePage();
        } catch (err) {
            if (viewModalName === modals[0]) {
                showMessage('error', 'Ошибка', `Направление не добавлено`);
            } else if (viewModalName === modals[1]) {
                showMessage('error', 'Ошибка', `Направление не изменено`);
            }
        }
    };

    const handleEditRoute = (val) => {
        setViewModalName(modals[1]);
        setEditRoute(val);
    };

    const handleCityNameChange = async (value) => {
        const result = await agent.PostalRoute.searchCities(value);
        setCitiesOption(result || []);
    };

    const handleRouteStatus = (e) => {
        if (viewModalName === modals[1]) {
            setEditRoute({ ...editRoute, route: e.value });
        } else if (viewModalName === modals[0]) {
            setAddRoute({ ...addRoute, route: e.value })
        }
    };

    const isSaveDisabled = () => {
        if (viewModalName === modals[1]) {
            return !statuses.some((stat) => stat.value === editRoute.route);
        }
        return viewModalName === modals[0] && (
            !addRoute.uslugaCode || !addRoute.route || !addRoute?.locationName?.kato
        );
    };

    const fileUploadRef = React.useRef(null);
    const [csvFile, setCsvFile] = React.useState(null);
    const [isSendLoading, setSendLoading] = React.useState(false)
    const clearCsvFile = () => {
        setCsvFile(null);
        if (fileUploadRef.current) { fileUploadRef.current.clear() }
    }
    const handleFileUpload = (e) => {
        setSendLoading(true)
        const formDataWithCsvFile = new FormData();
        formDataWithCsvFile.append('file', csvFile);
        agent.PostalRoute.uploadFile(formDataWithCsvFile)
            .then(res => {
                showMessage('success', 'Загрузка файла', 'Файл с направлениями успешно загружен');
                updatePage();
            })
            .catch(err => {
                showMessage('error', 'Ошибка при загрузке файла', `${(err?.response?.body || {message: ''}).message}`, 0, true);
            })
            .finally(() => {
                clearCsvFile()
                setSendLoading(false)
            });
    };
    const handleFileSelect = (e) => {
        const file = e.files[0];
        const splittedFileName = file.name.split('.');
        const extension = splittedFileName[splittedFileName.length - 1];
        if (extension === 'csv') { setCsvFile(file) }
        else {
          clearCsvFile();
          toast.current.show({
            severity: 'warn',
            summary: 'Ошибка при добавлении файла',
            detail: 'Добавляемый файл не является CSV файлом'
          });
        }
    };

    const handleDownload = async () => {
        const response = await agent.PostalRoute.downloadFile();
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(response.body);
        link.download = 'Направления.csv';
        link.click();
    };

    return (
        <div>
            <Toast ref={toast}/>
            <Dialog
                header={viewModalName === modals[1] ? "Редактирование статуса" : "Добавление направления"}
                visible={!!viewModalName}
                style={{ width: '60vw' }}
                footer={<Button disabled={isSaveDisabled()} label="Сохранить" onClick={handleSave} autoFocus />}
                onHide={() => setViewModalName('')}
            >
                <div>
                    {viewModalName === modals[1] ? 
                        <div className="p-grid">
                            <p className="p-col-3 p-mb-0">КАТО</p>
                            <p className="p-col-3 p-mb-0">Населенный пункт</p>
                            <p className="p-col-3 p-mb-0">Наименование Перевозчика</p>
                            <p className="p-col-3 p-mb-0">Код услуги Перевозчика</p>
                            <p className="p-col-3 p-mt-0">{editRoute?.kato?.kato}</p>
                            <p className="p-col-3 p-mt-0">{editRoute?.kato?.nameRu}</p>
                            <p className="p-col-3 p-mt-0">{editRoute?.postalServiceNameRu}</p>
                            <p className="p-col-3 p-mt-0">{editRoute?.postalCode}</p>
                        </div> : 
                        <div className="p-fluid p-formgrid p-grid">
                            <div className="p-field p-col-4 p-md-4 hiddenInput">
                                <label htmlFor="selectCityName">Наименование</label>
                                <SearchSelect
                                    name={"selectCityName"}
                                    state={addRoute.locationName}
                                    setState={(e) => setAddRoute({ ...addRoute, locationName: e })}
                                    options={citiesOption}
                                    optionValue={"kato"}
                                    onChange={handleCityNameChange}
                                    optionText={"name"}
                                />
                                {addRoute.locationName?.kato ? <p>КАТО: {addRoute.locationName?.kato}</p> : null}
                            </div>
                            <div className="p-field p-col-4 p-md-4">
                                <label htmlFor="firstname20">Перевозчик</label>
                                <Dropdown inputid="firstname20" value={addRoute.psId} options={props.postalServices}
                                            onChange={(e) => {setAddRoute({ ...addRoute, psId: e.value })}}
                                            optionLabel="nameRu" optionValue="id"/>
                            </div>
                            <div className="p-field p-col-4 p-md-4">
                                <label htmlFor="firstname21">Услуги</label>
                                <Dropdown inputid="firstname21" value={addRoute.uslugaCode} options={uslugasAddRoute}
                                            onChange={(e) => {setAddRoute({ ...addRoute, uslugaCode: e.value })}}
                                            disabled={!addRoute.psId} optionLabel="nameRu" optionValue="code"/>
                            </div>
                        </div>
                    }
                    <div>
                        <p>Статус</p>
                        <div className="p-grid">
                            {statuses.map((stat) => {
                                return (
                                    <div key={stat.value} className="p-col-3">
                                        <RadioButton
                                            inputId={stat.value}
                                            name="status"
                                            value={stat.value}
                                            onChange={handleRouteStatus}
                                            checked={viewModalName === modals[1] ? 
                                                editRoute?.route === stat?.value : 
                                                addRoute?.route === stat.value
                                            }
                                        />
                                        <label htmlFor={stat.value}>{stat?.label}</label>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>
            </Dialog>
            <Fieldset legend="Поиск" toggleable collapsed={pageData.panelCollapsed} onToggle={(e) => setPageData({ ...pageData, panelCollapsed: e.value })}>
                <form onSubmit={submitForm()}>
                    <div className="p-fluid p-formgrid p-grid">
                        <div className="p-field p-col-3 p-md-3">
                            <label htmlFor="cargos">Перевозчик</label>
                            <Dropdown inputid="cargos" value={pageData.psId} options={props.postalServices}
                                        onChange={(e) => {setPageData({ ...pageData, psId: e.value, uslugaId: null })}}
                                        optionLabel="nameRu" optionValue="id"/>
                        </div>
                        <div className="p-field p-col-3 p-md-2">
                            <label htmlFor="applications">Услуги</label>
                            <Dropdown inputid="applications" value={pageData.uslugaId} options={uslugasFilter}
                                        onChange={(e) => {setPageData({ ...pageData, uslugaId: e.value})}}
                                        disabled={!pageData.psId} optionLabel="nameRu" optionValue="id"/>
                        </div>
                        <div className="p-field p-col-3 p-md-2">
                            <label htmlFor="cato">КАТО</label>
                            <InputText 
                                inputid="cato" 
                                value={pageData.kato} 
                                type="number"
                                onChange={(e) => e.target.value.length < 10 && setPageData({ ...pageData, kato: e.target.value})}
                            />
                        </div>
                        <div className="p-field p-col-3 p-md-3">
                            <label htmlFor="placeName">Наименование</label>
                            <InputText inputid="placeName" value={pageData.nameRu} onChange={(e) =>
                                setPageData({ ...pageData, nameRu: e.target.value})}/>
                        </div>
                    </div>
                    <div className="p-d-flex p-jc-between p-ai-end">
                        <div className="p-d-flex p-flex-column">
                            <div className="p-d-flex p-mb-3">
                                {csvFile ? 
                                    <div className="p-d-flex">
                                        <Button type="button" icon="pi pi-times" label={`Отменить`} onClick={clearCsvFile} className="p-button-text p-mr-2" />
                                        <Button type="button" icon="pi pi-check" label={`Отправить ${csvFile.name}`} onClick={handleFileUpload} loading={isSendLoading}/>
                                    </div> : 
                                    <FileUpload 
                                        ref={fileUploadRef} 
                                        chooseLabel="Выбрать CSV файл" name="testFile"
                                        customUpload mode="basic" accept=".csv" 
                                        onSelect={handleFileSelect}
                                        uploadHandler={handleFileUpload}
                                    />
                                }
                                <div className="p-ml-4 p-d-flex p-ai-center">
                                    <a 
                                        className="link" 
                                        href={"/files/template.csv"}
                                        download
                                    >Скачать пример CSV файла</a>
                                </div>

                                <div className="p-ml-4">
                                    <Button 
                                        type="button" 
                                        onClick={() => setViewModalName(modals[0])} 
                                        className="p-button-link" 
                                        label="+ Добавить направление" 
                                    />
                                </div>
                            </div>
                            <div>
                                <Button 
                                    type="button" 
                                    onClick={handleDownload}
                                    className="p-button-link"
                                    label="Выгрузить таблицу"
                                />
                            </div>
                        </div>
                        <div className="p-grid">
                            <div className="p-mr-2">
                                <Button className="p-button-warning" label="Сбросить" onClick={clearForm()}/>
                            </div>
                            <div className="p-mr-2">
                                <Button type="submit" label="Найти"/>
                            </div>
                        </div>
                    </div>
                </form>
            </Fieldset>
            <div className="datatable-doc-demo">
                <div className="card">
                    <PostalRouteTable 
                        postalRoutes={props.postalRoutes}
                        patchPostalRoute={props.patchPostalRoute}
                        openModal={handleEditRoute}
                        statuses={statuses}
                        updatePage={updatePage}
                        showMessage={showMessage}
                    />
                    <Paginator currentPageReportTemplate="Показано с {first} по {last} из {totalRecords} записей"
                                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                first={pageData.first} rows={pageData.pageSize} totalRecords={(props.postalRoutes||{totalElements: 0}).totalElements} rowsPerPageOptions={[10, 25, 50]}
                                onPageChange={onPageChange}/>
                </div>
            </div>
        </div>
    );
};


export default connect(mapStateToProps, mapDispatchToProps)(PostalRoute);
