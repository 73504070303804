import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import React from 'react';
import { store, history} from './store';

import { Router } from 'react-router';
import { Route, Switch } from 'react-router-dom';

import App from './components/App';
import ScrollToTop from "./components/ScrollToTop";

ReactDOM.render((
  <Provider store={store}>
    <Router history={history} basename={process.env.PUBLIC_URL}>
      <ScrollToTop>
        <Switch>
          <Route path="/" component={App} />
        </Switch>
      </ScrollToTop>
    </Router>
  </Provider>

), document.getElementById('root'));
