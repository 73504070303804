import {
  LOGIN_ERROR,
  LOGOUT
} from '../constants/actionTypes';

export default (state = { messages: [] }, action) => {
  switch (action.type) {
    case LOGIN_ERROR:
      return {
        ...state,
        messages: [...state.messages, "Неверный логин или пароль"]
      };
    case LOGOUT:
      return {
        ...state,
        messages: []
      }
    default:
      return state;
  }
}