const parseDateNumber = (dateNum) => {
  return `${dateNum}`.length === 1 ? `0${dateNum}` : dateNum;
};

export const dateToString = (rawDate) => {
  const d = new Date(rawDate);
  
  const [day, month, year, hours, minutes] = [
    d.getDate(), d.getMonth()+1, d.getFullYear(), d.getHours(), d.getMinutes()
  ];

  return `${parseDateNumber(day)}.${parseDateNumber(month)}.${parseDateNumber(year)} ${parseDateNumber(hours)}:${parseDateNumber(minutes)}`;
}
