import {SET_STREET_ABBR} from '../constants/actionTypes';

const defaultState = {
  streetAbbrOptions: [],
};

const terminals = (state = defaultState, action) => {
  switch (action.type) {
    case SET_STREET_ABBR:
      return {
        ...state,
        streetAbbrOptions: action.payload
      };

    default:
      return state;
  }
};

export default terminals;