import {
    POSTAL_SERVICE_PAGE_LOADED,
    POSTAL_SERVICE_PAGE_UNLOADED,
    POSTAL_SERVICE_PAGE_ADD,
    POSTAL_SERVICE_PAGE_CHANGE_STATUS, POSTAL_SERVICE_FILTER_LOADED, POSTAL_SERVICE_FILTER_UNLOADED
} from '../constants/actionTypes';

export default (state = {}, action) => {
    switch (action.type) {
        case POSTAL_SERVICE_PAGE_LOADED:
            return {
                ...state,
                postalServices: action.payload,
            };
        case POSTAL_SERVICE_PAGE_UNLOADED:
            return {};
        case POSTAL_SERVICE_FILTER_LOADED:
            return {
                ...state,
                postalServicesFilter: action.payload,
            };
        case POSTAL_SERVICE_FILTER_UNLOADED:
            return {};
        case POSTAL_SERVICE_PAGE_ADD:
            return {
                ...state,
                commentErrors: action.error ? action.payload.errors : null,
                comments: action.error ?
                    null :
                    (state.comments || []).concat([action.payload.comment])
            };
        case POSTAL_SERVICE_PAGE_CHANGE_STATUS:
            const postalServiceId = action.id
            return {
                ...state,
                postalServices: state.postalServices.filter(postalService => postalService.id !== postalServiceId)
            };
        default:
            return state;
    }
};
