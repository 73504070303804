import {
    ORDER_PARCEL_LOADED, ORDER_PARCEL_UNLOADED
} from "../constants/actionTypes";

const defaultState = {
    orderParcel: {
        tracks: []
    }
};

export default (state = defaultState, action) => {
    switch (action.type) {
        case ORDER_PARCEL_LOADED:
            return {
                ...state,
                orderParcel: action.payload,
            }
        case ORDER_PARCEL_UNLOADED:
            return defaultState;
        default:
            return state;
    }
};
