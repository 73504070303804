import React from "react";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { history } from "../../../store";
import NewTariffDialog from "./NewTariffDialog";
import { Toast } from "primereact/toast";
import agent from "../../../agent";
import { CALC_TARIFF_PS_ZONES_LOAD, CALC_TARIFF_PS_ZONES_UNLOAD, CALC_TARIFF_USLUGA_PS_LOAD, 
  CALC_TARIFF_USLUGA_PS_UNLOAD } from "../../../constants/actionTypes";
import { connect } from "react-redux";
import { SearchFilters } from "./SearchFilters";
import { RemoveZonesConfirmDialog } from "./RemoveZonesConfirmDialog";
import { tariffTypesTranslate } from "./mockData/tariffTypesTranslate";
import { mileTypesTranslate } from "./mockData/mileTypesTranslate";

const mapStateToProps = state => ({
  calcTariff: state.calcTariff
});

const mapDispatchToProps = dispatch => ({
  onLoad: (psZonesPayload) => {
    dispatch({ type: CALC_TARIFF_PS_ZONES_LOAD, payload: psZonesPayload })
  },
  onSaveNewTariff: (psZonesPayload) => {
    dispatch({ type: CALC_TARIFF_PS_ZONES_LOAD, payload: psZonesPayload })
  },
  onRemoveTariff: (psZonesPayload) => {
    dispatch({ type: CALC_TARIFF_PS_ZONES_LOAD, payload: psZonesPayload })
  },
  onPostalServicePicked: (uslugaPsPayload) => {
    dispatch({ type: CALC_TARIFF_USLUGA_PS_LOAD, payload: uslugaPsPayload})
  },
  onUnload: () => {
    dispatch({ type: CALC_TARIFF_PS_ZONES_UNLOAD })
    dispatch({ type: CALC_TARIFF_USLUGA_PS_UNLOAD })
  }
});

const CalcTariffs = props => {
  const tariffsTable = React.useRef();
  const toastRef = React.useRef(null);
  const [isSendLoading, setSendLoading] = React.useState(false);

  const showMessage = (severity, summary, detail, life = 7000, sticky = false) => {
    toastRef.current.show({severity, summary, detail, life, sticky});
  }

  const reduxCalcTariff = props.calcTariff; // redux
  const reduxPsZones = reduxCalcTariff.psZones;
  const [psZones, setPsZones] = React.useState(reduxPsZones);

  {/* create new tariff */}
    const [showCreateDialog, setShowCreateDialog] = React.useState(false);
    const onCreateShow = () => setShowCreateDialog(true);
    const onCreateHide = () => setShowCreateDialog(false);

    const renderCreateFooter = () => {
      return (
        <div>
          <Button label="Отменить" icon="pi pi-times" onClick={onCreateHide} className="p-button-text" />
          <Button label="Сохранить" icon="pi pi-check" type="submit" form="createForm" autoFocus />
        </div>
      );
    }

    const newTariffInitial = {
      postalService: "",
      postalTariffId: "",
      tariffType: "",
      mileType: "",
      zoneName: ""
    }
    const [newTariffData, setNewTariffData] = React.useState(newTariffInitial);
    const { postalService, postalTariffId, tariffType, mileType, zoneName } = newTariffData;
    const updateNewTariffData = (keyName, value) => {
      setNewTariffData({ ...newTariffData, [keyName]: value });
    }

    // check for empty values
    const allIsValid = [postalTariffId, tariffType, mileType, zoneName].every(item => item);
    
    const onCreateSubmit = (e) => {
      e.preventDefault();

      if (allIsValid) {
        const newTariffDataToSend = {
          postalTariffId, 
          tariffType, 
          mileType, 
          zoneName
        };

        agent.CalcTariff.savePsZone(newTariffDataToSend)
          .then(res => {
            showMessage('success', 'Успешно', 'Новый тариф создан');
            props.onSaveNewTariff(agent.CalcTariff.getPsZones())
          })
          .catch(err => {
            showMessage('error', 'Ошибка при создании тарифа', `${err}`);
          })
          .finally(onCreateHide);
      }
      else { 
        showMessage('warn', 'Ошибка при создании тарифа', 'Все поля обязательны к заполнению');
      }
    }
  {/* /create new tariff */}

  React.useEffect(() => {
    props.onLoad(agent.CalcTariff.getPsZones());
    
    return () => { props.onUnload() }
  }, []);

  React.useEffect(() => {
    if (reduxCalcTariff.psZones) { setPsZones(reduxPsZones) }
  }, [reduxCalcTariff]);

  /* search filters */
    const [filteredPsZones, setFilteredPsZones] = React.useState(psZones);
    
    React.useEffect(() => { 
      if (!filteredPsZones) { setFilteredPsZones(psZones) }
    }, [psZones]);
    
    const filtersBundle = { 
      onPostalServicePicked: props.onPostalServicePicked, 
      reduxCalcTariff, psZones, filteredPsZones, setFilteredPsZones 
    };
  /* /search filters */

  /* remove zones confirm dialog */
    const [removeConfirmDialog, setRemoveConfirmDialog] = React.useState(false);
    const onShowRemoveConfirm = () => setRemoveConfirmDialog(true);
    const [selectedZone, setSelectedZone] = React.useState(null);

    const onRemoveSubmit = () => {
      setSendLoading(true);
      agent.CalcTariff.deletePsZone(selectedZone.id)
        .then(res => {
          if (res?.status === 202) {
            showMessage('info', 'Ваш запрос получен и находится в процессе обработки. Пожалуйста, подождите результатов.', res?.message ? res?.message : '', 0, true);            
            return;
          }
          showMessage('success', 'Успешно', 'Тарифная зона удалена');
          props.onRemoveTariff(agent.CalcTariff.getPsZones())
        })
        .catch(err => {
          showMessage('error', 'Ошибка при удалении тарифной зоны', `${err}`);
        })
        .finally(() => { setRemoveConfirmDialog(false); setSelectedZone(null); setSendLoading(false) });
    }

    const removeConfirmBundle = { 
      visible: removeConfirmDialog, setVisible: setRemoveConfirmDialog, 
      selected: selectedZone, setSelected: setSelectedZone,
      onRemoveSubmit, isSendLoading
    };
  /* /remove zones confirm dialog */

  const tariffTypeBodyTemplate = rowData => <span>{tariffTypesTranslate[rowData.tariffType]}</span>;
  const mileTypeBodyTemplate = rowData => <span>{mileTypesTranslate[rowData.mileType]}</span>;

  return (
    <div>
      <Toast ref={toastRef} />

      <RemoveZonesConfirmDialog removeConfirmBundle={removeConfirmBundle} />
      
      <NewTariffDialog 
        showCreateDialog={showCreateDialog}
        onCreateHide={onCreateHide}
        onCreateSubmit={onCreateSubmit}
        renderCreateFooter={renderCreateFooter}
        updateNewTariffData={updateNewTariffData}

        newTariffData={newTariffData}
        setNewTariffData={setNewTariffData}

        // form data
        postalService={postalService}
        postalTariffId={postalTariffId}
        tariffType={tariffType}
        mileType={mileType}
        zoneName={zoneName}
      />

      <div className="p-d-flex p-jc-between p-ai-center">
        <h1>Тарифы</h1>
        <Button className="p-d-block" label="Создать тариф" onClick={onCreateShow} icon="pi pi-plus" />          
      </div>

      <SearchFilters filtersBundle={filtersBundle} />
      <Button label={selectedZone ? "Удалить выбранную тарифную зону" : ""}
              icon={"pi pi-trash"} style={{minWidth: "3.2rem", transition: "all .1s"}}
              className={selectedZone ? "p-button-danger" : "p-button-text p-button-danger"} 
              onClick={onShowRemoveConfirm}
              disabled={!selectedZone}
      />

      <DataTable  ref={tariffsTable} value={filteredPsZones}
                  selection={selectedZone} onSelectionChange={(e) => { setSelectedZone(e.value); console.log(e.value) }}
                  onRowClick={(e) => { history.push(`/calc-tariff/${e.data.id}`) }}
                  className="p-datatable-customers" dataKey="id" rowHover
                  emptyMessage="Данные не найдены" scrollable
      >
          <Column selectionMode="single" headerStyle={{ width: '3rem' }} exportable={false} />
          <Column headerStyle={{ width: '100px' }} columnKey="id" field="id" header="№" />
          <Column headerStyle={{ width: '200px' }} columnKey="postalServiceDto.nameRu" field="postalServiceDto.nameRu" header="Грузоперевозчик" />
          <Column headerStyle={{ width: '200px' }} columnKey="psTariffDto.nameRu" field="psTariffDto.nameRu" header="Услуга грузоперевозчика" />
          <Column headerStyle={{ width: '200px' }} body={tariffTypeBodyTemplate} columnKey="tariffType" field="tariffType" header="Тип тарифа" />
          <Column headerStyle={{ width: '200px' }} body={mileTypeBodyTemplate} columnKey="mileType" field="mileType" header="Тип мили" />
          <Column headerStyle={{ width: '200px' }} columnKey="zoneName" field="zoneName" header="Наименование зоны" />
      </DataTable>
    </div>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(CalcTariffs);
