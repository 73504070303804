import {
    ORGANIZATION_LOAD, ORGANIZATION_UNLOAD, CLIENTS_LOAD
} from "../constants/actionTypes";

const defaultState = {
    orgs: [],
    clients: []
};

export default (state = defaultState, action) => {
    switch (action.type) {
        case ORGANIZATION_LOAD:
            return {
                ...state,
                orgs: action.payload,
            }
        case CLIENTS_LOAD:
            return {
                ...state,
                clients: action.payload,
            }
        case ORGANIZATION_UNLOAD:
            return defaultState;
        default:
            return state;
    }
};
