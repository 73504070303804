import {
    SLQ_USERS_LOAD,
    SLQ_USERS_UNLOAD,
    SLQ_USER_LOAD,
    SLQ_USER_UNLOAD,
    SLQ_USER_WALLET_LOAD,
    SLQ_USER_WALLET_HISTORY_LOAD,
    SLQ_USER_WALLET_UNLOAD,
    SLQ_USER_WALLET_HISTORY_UNLOAD, 
    SLQ_USER_OPTIONS_LOAD, 
    SLQ_USER_OPTIONS_UNLOAD,
    SLQ_USER_STATUSES,
    SLQ_USER_AVRS
} from "../constants/actionTypes";

const defaultState = {
    slqUser: {},
    slqUsers: [],
    wallet: {},
    walletHistory: [],
    slqUserOptions: [],
    statuses: [],
    avrs: []
};

export default (state = defaultState, action) => {
    switch (action.type) {
        case SLQ_USERS_LOAD:
            return {
                slqUsers: action.payload
            }
        case SLQ_USER_LOAD:
            return {
                ...state,
                slqUser: action.payload
            }
        case SLQ_USER_WALLET_LOAD:
            return {
                ...state,
                wallet: action.payload
            }
        case SLQ_USER_WALLET_HISTORY_LOAD:
            return {
                ...state,
                walletHistory: action.payload
            }
        case SLQ_USER_OPTIONS_LOAD:
            return {
                slqUserOptions: action.payload
            }
        case SLQ_USERS_UNLOAD:
            return {
                slqUsers: defaultState.slqUsers
            }
        case SLQ_USER_UNLOAD:
            return {
                slqUser: defaultState.slqUser
            }
        case SLQ_USER_AVRS:
            return {
                ...state,
                avrs: action.payload
            }

        case SLQ_USER_WALLET_UNLOAD:
            return {
                wallet: defaultState.wallet
            }
        case SLQ_USER_WALLET_HISTORY_UNLOAD:
            return {
                wallet: defaultState.walletHistory
            }
        case SLQ_USER_OPTIONS_UNLOAD:
            return {
                slqUserOptions: defaultState.slqUserOptions
            }
        case SLQ_USER_STATUSES: 
            return {
                ...state,
                statuses: action.payload
            }
        default:
            return state;
    }
}
