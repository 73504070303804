import React, {useEffect, useRef, useState } from "react";
import {connect} from "react-redux";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import { ColumnGroup } from 'primereact/columngroup';
import {Button} from "primereact/button";
import { Row } from 'primereact/row';
import { Paginator } from 'primereact/paginator';
import Header from "./Header";
import {PostalContext} from "../utils/context";
import agent from "../../../../agent";
import { PRODUCT_CATEGORY_LOADED, PRODUCT_CATEGORY_PAGE_OPTIONS } from "../../../../constants/actionTypes";

export const modals = ['add-product', 'edit-product'];
export const psGroupNames = ["Alem-Tat", "DPD", "GoPost", "EXLINE", "ЧГП", "SPARK"];
const postalServices = [
    [
        { code: "A", name: "Автоконсолидация по РК" },
        { code: "C", name: "По городу" },
        { code: "ECO", name: "Эконом" },
        { code: "E", name: "Экспресс" },
    ],
    [
        { code: "BZP", name: "18:00" },
        { code: "PCL", name: "OPTIMUM" },
        { code: "CUR", name: "CLASSIC" }
    ],        
    [
        { code: "P154", name: "Эконом доставка" },
        { code: "P153", name: "Эконом доставка по городу" },
        { code: "P151", name: '"Моя страна" РК' }
    ],
    [
        { code: "standard", name: "Эконом" },
        { code: "express", name: "Экспресс" }
    ],
    [
        { code: "PTC", name: "ЧГП/Собственная доставка" }
    ],
    [
        { code: "AUTO", name: "Spark Авто" },
        { code: "AVIA", name: "Spark Авиа" }
    ]
];

const mapStateToProps = state => ({
    ...state.productCategory,
});

const mapDispatchToProps = dispatch => ({
    onDataLoad: (data, pageOptions) => {
        dispatch({ type: PRODUCT_CATEGORY_LOADED, payload: data });
        dispatch({ type: PRODUCT_CATEGORY_PAGE_OPTIONS, payload: pageOptions });
    }
});

const PostalServiceTable = (props) => {
    const [selectedCustomers, setSelectedCustomers] = useState(null);
    const [editProduct, setEditProduct] = useState(null);
    const [modalView, setModalView] = useState('');
    const [filter, setFilter] = useState({
        category: '',
        code: ''
    });
    const dt = useRef(null);

    const getStatusLabel = (rowData) => {
        return rowData.status === 'ACTIVE' ? 'Присутствует' : 'Отсутствует';
    };

    const getPostalLabel = (rowData, postal) => {
        return rowData?.postal_service?.find((code) => code === postal.code) ? 'Да' : 'Нет';
    };

    const getNoteFieldBody = (val) => {
        const handleClick = () => {
            setEditProduct(val);
            setModalView(modals[1]);
        };

        return (
            <div className="p-d-flex p-ai-center p-jc-between">
                <span style={{maxWidth: '80%'}} className="p-mr-2">{val?.note}</span> 
                <Button
                    onClick={handleClick}
                    className="p-button-text p-button-rounded" 
                    icon="pi pi-pencil" 
                />
            </div>
        )
    };

    const headerGroup = (
        <ColumnGroup>
            <Row>
                <Column className="product-category-table" style={{width:'8%'}} sortable header="Категория 3 уровня" rowSpan={2} />
                <Column className="product-category-table" style={{width:'8%'}} header="CODE" rowSpan={2} />
                <Column className="product-category-table" style={{width:'8%'}} header="Наличие доставки" rowSpan={2} />
                {psGroupNames.map((name, index) => (
                    <Column key={index} className="product-category-table" header={name} colSpan={postalServices[index]?.length} />
                ))}
                <Column className="product-category-table" style={{width:'15%'}} header="Дополнительные требования по упаковке" field="note" rowSpan={2} />
            </Row>
            <Row>
                {postalServices.reduce((acc, group) => ([...acc, ...group]), [])
                    .map(({code, name}) => (
                        <Column className="product-category-table" style={{width:'4%'}} key={code} field="postal_service" header={name} />)
                    )
                }
            </Row>
        </ColumnGroup>
    );

    const [first, setFirst] = useState(0);

    const fetchData = async (page, pageSize, newFirst) => {
        const response = await agent.ProductCategory.all(filter.category, filter.code, page, pageSize);
        const pageOptions = {
            size: response.size,
            totalElements: response.totalElements,
            totalPages: response.totalPages,
            number: response.number
        }
        props.onDataLoad(response.content, pageOptions);
        setFirst(newFirst);
    };

    const onBasicPageChange = (event) => {        
        fetchData(event.page, event.rows, event.first);
    };
    
    return (
        <PostalContext.Provider value={{carriers: postalServices, data: editProduct, setData: setEditProduct}}>
            <div>
                <Header 
                    filter={filter} 
                    setFilter={setFilter} 
                    setFirst={setFirst} 
                    modalView={modalView} 
                    setModalView={setModalView} 
                />
                <div className="datatable-doc-demo product-category-table">
                    <div className="card">
                        <DataTable
                            headerColumnGroup={headerGroup}
                            ref={dt} 
                            value={props.productCategories} 
                            className="p-datatable-sm" 
                            dataKey="id"
                            rowHover
                            selection={selectedCustomers}
                            onSelectionChange={e => setSelectedCustomers(e.value)} 
                            responsive
                            showGridlines
                            emptyMessage="Данные не найдены" 
                            paginator={false}
                        >
                            <Column columnKey="name_ru" field="name_ru"/>
                            <Column columnKey="code" field="code" />
                            <Column columnKey="status" field="status" body={getStatusLabel} />
                            {postalServices
                                .reduce((acc, group) => ([...acc, ...group]), [])
                                .map((postal) => (
                                    <Column
                                        key={postal.code}
                                        columnKey="postal_services" 
                                        field="postal_service" 
                                        body={(rowData) => getPostalLabel(rowData, postal)} 
                                    />
                                )
                            )}
                            <Column columnKey="note" field="note" body={getNoteFieldBody} />
                        </DataTable>
                        <Paginator
                            first={first}
                            rows={props.pageOptions.size}
                            totalRecords={props.pageOptions.totalElements}
                            rowsPerPageOptions={[10, 20, 50]}
                            onPageChange={onBasicPageChange}
                        />
                    </div>
                </div>
            </div> 
        </PostalContext.Provider>
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(PostalServiceTable);
