import { useEffect, useState } from "react";
import { AutoComplete } from "primereact/autocomplete";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { ToggleButton } from "primereact/togglebutton";
import { InputNumber } from 'primereact/inputnumber';
import { InputTextarea } from "primereact/inputtextarea";
import { Message } from 'primereact/message';
import { throttle } from "..";
import agent from "../../../agent";
import "../styles.css";

const searchApi = throttle();

const initialTerminal = {
  city: null,
  street: '',
  house: '',
  office: '',
  streetType: null,
  cargo: null,
  ptcDelivery: true,
  name: '',
  lastName: '',
  surname: '',
  phone: null,
  time: [[],[],[],[],[],[],[],[]],
  comment: '',
  codeDPD: ''
};

const requiredFields = [
  "city",
  "street",
  "house",
  "streetType",
  "cargo",
];

const checkRequireds = (data, addition) => {
  return [...requiredFields, ...addition]
    .filter((field) => {
      return !data[field]
    });
};

const days = ['Пн.', 'Вт.', 'Ср.', 'Чт.', 'Пт.', 'Сб.', 'Вс.'];

const DialogEditTerminal = ({
  viewDialog, 
  hideDialog, 
  cities, 
  streetAbbrOptions, 
  postalServices,
  editTerminal,
  showMessage,
  onSuccess
}) => {
  const [cityOptions, setCityOptions] = useState([]);
  const [terminal, setTerminal] = useState(initialTerminal);
  const [requireds, setRequireds] = useState([]);

  useEffect(() => {
    if (editTerminal) {
      setTerminal({
        ...terminal, 
        comment: editTerminal?.message || '',
        city: cities?.find((c) => c?.kato === editTerminal?.kato) || null,
        street: editTerminal?.street || '',
        house: editTerminal?.house || '',
        office: editTerminal?.office || '',
        streetType: editTerminal?.street_abbr_id || null,
        cargo: editTerminal?.postal_service_id || null,
        ptcDelivery: editTerminal?.is_term_ptc,
        name: editTerminal?.dispatcher?.firstName || '',
        lastName: editTerminal?.dispatcher?.lastName || '',
        surname: editTerminal?.dispatcher?.middleName || '',
        phone: +editTerminal?.dispatcher?.phone || null,
        codeDPD: editTerminal?.codeDPD || '',
        time: terminal.time.map((t, index) => {
          const day = editTerminal.timeJson[index];
          return [day?.start ? new Date(`December 1, 2023 ${day.start}:00`) : null, day?.end ? new Date(`December 1, 2023 ${day.end}:00`) : null];
        })
      });
    }
  }, [editTerminal]);

  const handleTerminals = (key, value) => {
    setTerminal({...terminal, [key]: value});
    if (value && requireds.includes(key)) {
      setRequireds(requireds.filter((field) => field !== key));
    }
  };

  const fetchCities = async (value) => {
    const data = await agent.Terminals.searchCity(value);
    setCityOptions(data);
  };

  const searchCity = (event) => {
    searchApi(async () => fetchCities(event.query), 250);
  };

  const handleTime = (type, index, value) => {
    const newTime = [...terminal.time];
    if (type === 'clear') {
      newTime[index] = [];
    } else if (index!==7) {
      newTime[index][type === 'start' ? 0 : 1] = value;
    } else {
      newTime.map((dayTime) => {
        dayTime[type === 'start' ? 0 : 1] = value;
        return dayTime;
      });
    }
    setTerminal({...terminal, time: newTime});
  };

  const handleSave = async (e) => {
    e.preventDefault();
    const reqs = checkRequireds(
      terminal, 
      terminal.cargo === 1 ? ['codeDPD'] : 
      terminal.cargo === 6 ? ["name", "lastName", "phone"] : []
    );

    if (reqs.length) {
      setRequireds(reqs);
      showMessage('error', 'Ошибка', 'Заполните все обязательные поля');
      return;
    }

    try {
      const data = {
        "street": terminal.street,
        "house": terminal.house,
        "office": terminal.office,
        "kato": cities?.find((c) => c?.kato === terminal?.city?.kato)?.kato || '',
        "street_abbr_id": terminal.streetType,
        "postal_service_id": terminal.cargo,
        "is_term_ptc": terminal.ptcDelivery,
        "message": terminal.comment,
        "codeDPD": terminal.codeDPD,
        "timeJson": terminal.time.slice(0, 7).map((t, index) => ({
          "day": index+1,
          "weekend": index>=5,
          "start": t[0] ? t[0]?.toLocaleTimeString('ru')?.slice(0,5) : null,
          "end": t[1] ? t[1]?.toLocaleTimeString('ru')?.slice(0,5) : null
        })),
        "dispatcher": 
          terminal.name || terminal.lastName || terminal.surname || terminal.phone ? 
          {
            "firstName": terminal.name,
            "lastName": terminal.lastName,
            "middleName": terminal.surname,
            "phone": terminal.phone
          } : null,
      };

      if (editTerminal) {
        await agent.Terminals.updateTerminal(editTerminal.id, data);
        showMessage('success', 'Редактрование терминала', 'Терминал успешно изменен');
        onSuccess();
      } else {
        await agent.Terminals.addTerminal(data);
        showMessage('success', 'Создание терминала', 'Новый терминал успешно создан');
        onSuccess();
      }
    } catch (err) {
      closeDialog();
      showMessage('error', 'Ошибка', `Терминал не ${editTerminal ? 'изменен' : 'добавлен'}`);
    }
  };

  const dialogFooter = () => {
    return (
      <div>
        <Button label="Отменить" icon="pi pi-times" onClick={closeDialog} className="p-button-text" />
        <Button label="Сохранить" type='submit' icon="pi pi-check" onClick={handleSave} autoFocus />
      </div>
    );
  };

  const closeDialog = () => {
    setTerminal(initialTerminal);
    hideDialog();
  };

  return (
    <Dialog
      header={editTerminal ? 'Редактирование терминала' : 'Добавление терминала'}
      visible={viewDialog} 
      style={{ width: '60vw' }} 
      footer={dialogFooter}
      onHide={closeDialog}
    >
      <div>
        <div className="p-fluid p-formgrid p-grid">
          <div className="p-field p-col-12 p-md-4">
            <label htmlFor="city">Город</label>
            <AutoComplete
              id="city"
              className={`p-mt-1 ${requireds.includes('city') ? 'p-invalid': ''}`}
              value={terminal.city} 
              suggestions={cityOptions} 
              completeMethod={searchCity} 
              field="name" 
              dropdown 
              onChange={(e) => handleTerminals('city', e.value)} 
            />
          </div>
          
          <div className="p-field p-col-12 p-md-4">
            <label htmlFor="street">Улица</label>
            <InputText 
              required 
              id={'street'} 
              type="text" 
              className={`p-mt-1 p-d-block p-mb-2 ${requireds.includes('street') ? 'p-invalid': ''}`} 
              placeholder="Название улицы" 
              value={terminal.street}
              onChange={(e) => handleTerminals('street', e.target.value)}
            />
          </div>

          <div className="p-field p-col-12 p-md-4">
            <label htmlFor="house">Дом</label>
            <InputText 
              required 
              id={'house'} 
              type="text" 
              className={`p-mt-1 p-d-block p-mb-2 ${requireds.includes('house') ? 'p-invalid': ''}`} 
              placeholder="Номер дома" 
              value={terminal.house}
              onChange={(e) => handleTerminals('house', e.target.value)}
            />
          </div>

          <div className="p-field p-col-12 p-md-4">
            <label htmlFor="office">Офис</label>
            <InputText 
              style={{width: '100%'}} 
              id={'office'} 
              type="text" 
              className="p-mt-1 p-d-block p-mb-2" 
              placeholder="Номер офиса" 
              value={terminal.office}
              onChange={(e) => handleTerminals('office', e.target.value)}
            />
          </div>

          <div className="p-field p-col-12 p-md-4">
            <label htmlFor="streetAbbr">Тип улицы</label>
            <Dropdown 
              inputId="streetAbbr" 
              value={terminal.streetType} 
              className={`p-mt-1 ${requireds.includes('streetType') ? 'p-invalid': ''}`}
              options={streetAbbrOptions}
              onChange={(e) => handleTerminals('streetType', e.value)}
              optionLabel="nameRu" 
              optionValue="id" 
              showClear
            />
          </div>

          <div className="p-field p-col-12 p-md-4">
            <label htmlFor="psId">Грузоперевозчик</label>
            <Dropdown
              inputId="psId"
              value={terminal.cargo}
              className={`p-mt-1 ${requireds.includes('cargo') ? 'p-invalid': ''}`}
              options={postalServices}
              onChange={(e) => handleTerminals('cargo', e.value)}
              optionLabel="nameRu"
              optionValue="id"
              showClear
            />
          </div>

          <div className="p-field p-col-12 p-md-4 p-d-flex p-flex-column">
            <Message 
              className="message-info-label"
              severity="info"
              text="Доставка PTC" 
            />
            <ToggleButton 
              id="ptcDelivery"
              className="p-mt-1" 
              checked={terminal.ptcDelivery} 
              onChange={(e) => e} 
              onLabel="Да" 
              offLabel="Нет" 
              onIcon="pi pi-check" 
              offIcon="pi pi-times"
              tooltip="Данный терминал выбран для забора заказов PTC"
              tooltipOptions={{position: 'right'}}
              style={{width: '9rem'}}
            />
          </div>

          {terminal.cargo === 1 ? 
            <div className="p-field p-col-12 p-md-4">
              <label htmlFor="office">Код терминала DPD</label>
              <InputText
                required
                style={{width: '100%'}} 
                id={'codeDPD'} 
                type="text" 
                className={`p-mt-1 p-d-block p-mb-2 ${requireds.includes('codeDPD') ? 'p-invalid': ''}`} 
                value={terminal.codeDPD}
                onChange={(e) => handleTerminals('codeDPD', e.target.value)}
              />
            </div> : null
          }
        </div>

        <h4>Диспетчер терминала SLQ</h4>
        <div className="p-fluid p-formgrid p-grid">
          <div className="p-field p-col-12 p-md-4">
            <label htmlFor={"name"}>Имя</label>
            <InputText 
              disabled={terminal.cargo!==6}
              required 
              style={{width: '100%'}} 
              id={'name'} 
              type="text" 
              className={`p-mt-1 p-d-block p-mb-2 ${requireds.includes('name') ? 'p-invalid': ''}`} 
              placeholder="Введите имя" 
              value={terminal.name}
              onChange={(e) => handleTerminals('name', e.target.value)}
            />
          </div>

          <div className="p-field p-col-12 p-md-4">
            <label htmlFor="lastName">Фамилия</label>
            <InputText 
              disabled={terminal.cargo!==6}
              required 
              style={{width: '100%'}} 
              id={'lastName'} 
              type="text" 
              className={`p-mt-1 p-d-block p-mb-2 ${requireds.includes('lastName') ? 'p-invalid': ''}`} 
              placeholder="Введите фамилию" 
              value={terminal.lastName}
              onChange={(e) => handleTerminals('lastName', e.target.value)}
            />
          </div>

          <div className="p-field p-col-12 p-md-4">
            <label htmlFor="surname">Отчество</label>
            <InputText 
              disabled={terminal.cargo!==6}
              style={{width: '100%'}} 
              id={'surname'} 
              type="text" 
              className="p-mt-1 p-d-block p-mb-2" 
              placeholder="Введите отчество" 
              value={terminal.surname}
              onChange={(e) => handleTerminals('surname', e.target.value)}
            />
          </div>

          {/* <div className="p-field p-col-12 p-md-4">
            <label style={{width: '100%'}} htmlFor="phone">Номер телефона</label>
            <InputMask 
              required 
              className="p-mt-1" 
              id="phone" 
              mask="(999) 999-9999" 
              value={''} 
              placeholder="(999) 999-9999" 
              onChange={(e) => console.log(e.value)}
            />
          </div> */}

          <div className="p-field p-col-12 p-md-4">
            <label style={{width: '100%'}} htmlFor="phone">Номер телефона</label>
            <InputNumber 
              disabled={terminal.cargo!==6}
              className={`p-mt-1 ${requireds.includes('phone') ? 'p-invalid': ''}`}
              id="phone" 
              value={terminal.phone} 
              onValueChange={(e) => handleTerminals('phone', e.value)} 
              mode="decimal" 
              useGrouping={false} 
            />
          </div>
        </div>

        <h4>Время работы терминала</h4>
        <div className="p-fluid p-formgrid p-grid">
          <div className="p-fluid p-formgrid p-grid p-ml-2">
            <div className="p-field p-col-12 p-md-5" style={{width: '14rem'}}>
              <p><b>{'Установить для всех дней'}:</b></p>
            </div>
            <div className="p-field p-col-12 p-md-2">
              <Calendar 
                id="time-start" 
                value={terminal.time[7][0]} 
                onChange={(e) => handleTime('start', 7, e.value)} 
                timeOnly 
              />
            </div>
            <p>-</p>
            <div className="p-field p-col-12 p-md-2">
              <Calendar 
                id="time-end" 
                value={terminal.time[7][1]} 
                onChange={(e) => handleTime('end', 7, e.value)} 
                timeOnly 
              />
            </div>
          </div>

          <div className="p-field p-col-12 p-md-6">
            {days.slice(0, 4).map((d, index) => {
              return (
                <div key={index} className="p-fluid p-formgrid p-grid p-ml-1">
                  <div className="p-field p-col-12 p-md-2" style={{width: '3rem'}}>
                    <p><b>{d}:</b></p>
                  </div>
                  <div className="p-field p-col-12 p-md-3">
                    <Calendar 
                      id={`time-start-${index}`} 
                      value={terminal.time[index][0]} 
                      onChange={(e) => handleTime('start', index, e.value)} 
                      timeOnly 
                    />
                  </div>
                  <p>-</p>
                  <div className="p-field p-col-12 p-md-3">
                    <Calendar 
                      id={`time-end-${index}`}
                      value={terminal.time[index][1]} 
                      onChange={(e) => handleTime('end', index, e.value)} 
                      timeOnly 
                    />
                  </div>
                  <Button
                    onClick={() => handleTime('clear', index)}
                    className="p-button-text p-ml-3 p-button-rounded" 
                    icon="pi pi-times" 
                  />
                </div>
              );
            })}
          </div>

          <div className="p-field p-col-12 p-md-6">
            {days.slice(4).map((d, i) => {
              const index = i+4;
              return (
                <div key={index} className="p-fluid p-formgrid p-grid p-ml-2">
                  <div className="p-field p-col-12 p-md-2" style={{width: '3rem'}}>
                    <p><b>{d}:</b></p>
                  </div>
                  <div className="p-field p-col-12 p-md-3">
                    <Calendar 
                      id={`time-start-${index}`}
                      value={terminal.time[index][0]} 
                      onChange={(e) => handleTime('start', index, e.value)} 
                      timeOnly 
                    />
                  </div>
                  <p>-</p>
                  <div className="p-field p-col-12 p-md-3">
                    <Calendar 
                      id={`time-end-${index}`}
                      value={terminal.time[index][1]} 
                      onChange={(e) => handleTime('end', index, e.value)} 
                      timeOnly 
                    />
                  </div>
                  <Button
                    onClick={() => handleTime('clear', index)}
                    className="p-button-text p-ml-3 p-button-rounded" 
                    icon="pi pi-times" 
                  />
                </div>
              );
            })}
          </div>
        </div>

        <h4>Дополнительный комментарий</h4>
        <div className="p-fluid p-formgrid p-grid">
          <div className="p-field p-col-12 p-md-12">
            <InputTextarea
              className="p-mt-1" 
              style={{width: '100%'}}
              value={terminal.comment} 
              onChange={(e) => handleTerminals('comment', e.target.value)} 
              rows={1} cols={50} autoResize 
            />
          </div>
        </div>
      </div>  
    </Dialog>
  );
};

export default DialogEditTerminal;