export const APP_LOAD = 'APP_LOAD';
export const REDIRECT = 'REDIRECT';
export const SETTINGS_SAVED = 'SETTINGS_SAVED';
export const DELETE_ARTICLE = 'DELETE_ARTICLE';
export const SETTINGS_PAGE_UNLOADED = 'SETTINGS_PAGE_UNLOADED';
export const HOME_PAGE_LOADED = 'HOME_PAGE_LOADED';
export const HOME_PAGE_UNLOADED = 'HOME_PAGE_UNLOADED';
export const SET_PAGE = 'SET_PAGE';
export const APPLY_TAG_FILTER = 'APPLY_TAG_FILTER';
export const CHANGE_TAB = 'CHANGE_TAB';
export const PROFILE_PAGE_LOADED = 'PROFILE_PAGE_LOADED';
export const PROFILE_PAGE_UNLOADED = 'PROFILE_PAGE_UNLOADED';
export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';
export const REGISTER = 'REGISTER';
export const LOGIN_PAGE_UNLOADED = 'LOGIN_PAGE_UNLOADED';
export const REGISTER_PAGE_UNLOADED = 'REGISTER_PAGE_UNLOADED';
export const ASYNC_START = 'ASYNC_START';
export const ASYNC_END = 'ASYNC_END';
export const EDITOR_PAGE_LOADED = 'EDITOR_PAGE_LOADED';
export const EDITOR_PAGE_UNLOADED = 'EDITOR_PAGE_UNLOADED';
export const ADD_TAG = 'ADD_TAG';
export const REMOVE_TAG = 'REMOVE_TAG';
export const UPDATE_FIELD_AUTH = 'UPDATE_FIELD_AUTH';
export const UPDATE_FIELD_EDITOR = 'UPDATE_FIELD_EDITOR';
export const FOLLOW_USER = 'FOLLOW_USER';
export const UNFOLLOW_USER = 'UNFOLLOW_USER';
export const PROFILE_FAVORITES_PAGE_UNLOADED = 'PROFILE_FAVORITES_PAGE_UNLOADED';
export const PROFILE_FAVORITES_PAGE_LOADED = 'PROFILE_FAVORITES_PAGE_LOADED';
export const POSTAL_SERVICE_PAGE_LOADED = 'POSTAL_SERVICE_PAGE_LOADED';
export const POSTAL_SERVICE_PAGE_UNLOADED = 'POSTAL_SERVICE_PAGE_UNLOADED';
export const POSTAL_SERVICE_PAGE_ADD = "POSTAL_SERVICE_PAGE_ADD";
export const POSTAL_SERVICE_PAGE_CHANGE_STATUS = "POSTAL_SERVICE_PAGE_CHANGE_STATUS";
export const POSTAL_SERVICE_FILTER_LOADED = 'POSTAL_SERVICE_FILTER_LOADED';
export const POSTAL_SERVICE_FILTER_UNLOADED = 'POSTAL_SERVICE_FILTER_UNLOADED';

export const POSTAL_ROUTE_PAGE_LOADED = 'POSTAL_ROUTE_PAGE_LOADED';
export const POSTAL_ROUTE_PAGE_UNLOADED = 'POSTAL_ROUTE_PAGE_UNLOADED';
export const POSTAL_ROUTE_PAGE_ADD = "POSTAL_ROUTE_PAGE_ADD";
export const POSTAL_ROUTE_PAGE_CHANGE_ROUTE = "POSTAL_ROUTE_PAGE_CHANGE_ROUTE";

export const ORDER_PAGE_LOADED = 'ORDER_PAGE_LOADED';
export const ORDER_PAGE_UNLOADED = 'ORDER_PAGE_UNLOADED';

export const ORDER_STATUS_LOADED = 'ORDER_STATUS_LOADED';
export const ORDER_STATUS_UNLOADED = 'ORDER_PAGE_UNLOADED';


export const ORDER_LOADED = 'ORDER_LOADED';
export const ORDER_UNLOADED = 'ORDER_UNLOADED';
export const ORDER_PS_TRACK_RELOADED = 'ORDER_PS_TRACK_RELOADED';

export const ORDER_CHILDREN_LOADED = 'ORDER_CHILDREN_LOADED';
export const ORDER_CHILDREN_UNLOADED = 'ORDER_CHILDREN_UNLOADED';

export const PRODUCT_CATEGORY_LOADED = 'PRODUCT_CATEGORY_LOADED';
export const PRODUCT_CATEGORY_ADD = 'PRODUCT_CATEGORY_ADD';
export const PRODUCT_CATEGORY_PAGE_OPTIONS = 'PRODUCT_CATEGORY_PAGE_OPTIONS';

export const OFFER_LOADED = 'OFFER_LOADED';
export const OFFER_UNLOADED = 'OFFER_UNLOADED';

export const ORDER_PARCEL_LOADED = 'ORDER_PARCEL_LOADED';
export const ORDER_PARCEL_UNLOADED = 'ORDER_PARCEL_UNLOADED';

export const TASK_QUEUE_LOAD = 'TASK_QUEUE_LOAD';
export const TASK_QUEUE_LOADED = 'TASK_QUEUE_LOADED';
export const TASK_QUEUE_UNLOADED = 'TASK_QUEUE_UNLOADED';

export const TASK_QUEUE_PATCH = 'TASK_QUEUE_PATCH';
export const TASK_QUEUE_ADD = 'TASK_QUEUE_ADD';

export const LOGIN_ERROR = 'LOGIN_ERROR';
export const SEARCH_STATE_UPDATE = 'SEARCH_STATE_UPDATE';

export const REPORT_LOAD = 'REPORT_LOAD';
export const REPORT_UNLOAD = 'REPORT_UNLOAD';

export const CHANGE_REQUIRED_FIELD = 'CHANGE_REQUIRED_FIELD';
export const CLEAR_ALL_FIELDS = 'CLEAR_ALL_FIELDS';
export const CHANGE_NOT_REQUIRED_FIELD = 'CHANGE_NOT_REQUIRED_FIELD';
export const CLEAR_NOT_REQUIRED_FIELDS = 'CLEAR_NOT_REQUIRED_FIELDS';

export const INVOICE_LOAD = 'INVOICE_LOAD';
export const INVOICE_UNLOAD = 'INVOICE_UNLOAD';

export const ORGANIZATION_LOAD = 'ORGANIZATION_LOAD';
export const ORGANIZATION_UNLOAD = 'ORGANIZATION_UNLOAD';
export const CLIENTS_LOAD = 'CLIENTS_LOAD';

export const UI_LOCK = 'UI_LOCK';
export const UI_UNLOCK = 'UI_UNLOCK';

export const SLQ_USERS_LOAD = 'SLQ_USERS_LOAD';
export const SLQ_USERS_UNLOAD = 'SLQ_USERS_UNLOAD';

export const SLQ_USER_LOAD = 'SLQ_USER_LOAD';
export const SLQ_USER_UNLOAD = 'SLQ_USER_UNLOAD';

export const SLQ_USER_WALLET_LOAD = 'SLQ_USER_WALLET_LOAD';
export const SLQ_USER_WALLET_UNLOAD = 'SLQ_USER_WALLET_UNLOAD';

export const SLQ_USER_WALLET_HISTORY_LOAD = 'SLQ_USER_WALLET_HISTORY_LOAD';
export const SLQ_USER_WALLET_HISTORY_UNLOAD = 'SLQ_USER_WALLET_HISTORY_UNLOAD';

export const SLQ_USER_OPTIONS_LOAD = 'SLQ_USER_OPTIONS_LOAD';
export const SLQ_USER_OPTIONS_UNLOAD = 'SLQ_USER_OPTIONS_UNLOAD';

export const SLQ_USER_STATUSES = 'SLQ_USER_STATUSES';
export const SLQ_USER_AVRS = 'SLQ_USER_AVRS';

export const CALC_TARIFF_POSTAL_SERVICES_LOAD = 'CALC_TARIFF_POSTAL_SERVICES_LOAD';
export const CALC_TARIFF_POSTAL_SERVICES_UNLOAD = 'CALC_TARIFF_POSTAL_SERVICES_UNLOAD';

export const CALC_TARIFF_USLUGA_PS_LOAD = 'CALC_TARIFF_USLUGA_PS_LOAD';
export const CALC_TARIFF_USLUGA_PS_UNLOAD = 'CALC_TARIFF_USLUGA_PS_UNLOAD';

export const CALC_TARIFF_PS_ZONES_LOAD = 'CALC_TARIFF_PS_ZONES_LOAD';
export const CALC_TARIFF_PS_ZONES_UNLOAD = 'CALC_TARIFF_PS_ZONES_UNLOAD';

export const CALC_TARIFF_PS_ZONE_KATO_LIST_LOAD = 'CALC_TARIFF_PS_ZONE_KATO_LIST_LOAD';
export const CALC_TARIFF_PS_ZONE_KATO_LIST_UNLOAD = 'CALC_TARIFF_PS_ZONE_KATO_LIST_UNLOAD';

export const CALC_TARIFF_WEIGHT_LIST_LOAD = 'CALC_TARIFF_WEIGHT_LIST_LOAD';
export const CALC_TARIFF_WEIGHT_LIST_UNLOAD = 'CALC_TARIFF_WEIGHT_LIST_UNLOAD';

export const CALC_TARIFF_TAWAY_LIST_LOAD = 'CALC_TARIFF_TAWAY_LIST_LOAD';
export const CALC_DEDUCTION_TT_LIST_LOAD = 'CALC_DEDUCTION_TT_LIST_LOAD';

export const SET_STREET_ABBR = 'SET_STREET_ABBR';