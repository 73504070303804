import {
  SEARCH_STATE_UPDATE
} from "../constants/actionTypes"

const defaultState = {
  searchState: {
    params: null,
    urlValue: false,
  }
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case SEARCH_STATE_UPDATE:
      return {
        searchState: {
          params: action.payload.params || null,
          urlValue: action.payload.urlValue || false,
        }
      }
    default: 
      return state;
  }
}