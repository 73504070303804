import React, {useState, lazy, Suspense, useRef} from "react";
import {connect} from "react-redux";
import {InputText} from "primereact/inputtext";
import {Button} from "primereact/button";
import {Toast} from 'primereact/toast';
import {FileUpload} from 'primereact/fileupload';
import agent from "../../../../agent";
import {PRODUCT_CATEGORY_LOADED, PRODUCT_CATEGORY_PAGE_OPTIONS} from "../../../../constants/actionTypes";
import styles from "../../../Calculator/Calculator.module.css"
import { modals } from "./PostalService";

const Modal = lazy(() => import("./Modal"));

const mapStateToProps = state => ({
    ...state.productCategory,
});

const mapDispatchToProps = dispatch => ({
    onDataLoad: (data, pageOptions) => {
        dispatch({ type: PRODUCT_CATEGORY_LOADED, payload: data });
        dispatch({ type: PRODUCT_CATEGORY_PAGE_OPTIONS, payload: pageOptions });
    }
});

const Header = ({filter, setFilter, setFirst, onDataLoad, modalView, setModalView, pageOptions}) => {
    const toastRef = React.useRef(null);
    const fileUploadRef = React.useRef(null);
    const [csvFile, setCsvFile] = React.useState(null);
    const [isSendLoading, setSendLoading] = React.useState(false);
    
    const showMessage = (severity, summary, detail, sticky = false) => {
        toastRef.current.show({severity: severity, summary: summary, detail: detail, sticky});
    };

    const handleFilterChange = (key, value) => {
        setFilter({...filter, [key]: value});
    };

    const clearCsvFile = () => {
        setCsvFile(null);
        if (fileUploadRef.current) { fileUploadRef.current.clear() }
    };

    const handleFileSelect = (e) => {
        const file = e.files[0];
        const splittedFileName = file.name.split('.');
        const extension = splittedFileName[splittedFileName.length - 1];
  
        if (extension === 'csv') { setCsvFile(file) }
        else {
          clearCsvFile();
  
          toastRef.current.show({
            severity: 'warn',
            summary: 'Ошибка при добавлении CSV файла',
            detail: 'Добавляемый файл не является CSV файлом'
          });
        }
    };

    const handleFileUpload = (e) => {
        setSendLoading(true)
        const formDataWithCsvFile = new FormData();
        formDataWithCsvFile.append('file', csvFile);

        agent.ProductCategory.addList(formDataWithCsvFile, 0, pageOptions.size)
          .then((res) => {
            showMessage('success', 'Успешно', 'CSV файл загружен');
            setPageOption(res);
          })
          .catch((err) => {
            if (err?.response?.body?.message) {
                showMessage('error', 'Ошибка при загрузке CSV файла', err.response.body.message, true);
            } else {
                showMessage('error', 'Ошибка при загрузке CSV файла', "Не известная ошибка");
            }
            console.error(err);
          })
          .finally(() => {
            clearCsvFile();
            setSendLoading(false);
          });
    };

    const setPageOption = async (data, newFirst = 0) => {
        const pageOptions = {
            size: data.size,
            totalElements: data.totalElements,
            totalPages: data.totalPages,
            number: data.number
        }
        onDataLoad(data.content, pageOptions);
        setFirst(newFirst);
    };

    const handleSearch = async () => {
        const response = await agent.ProductCategory.all(filter.category, filter.code, 0, pageOptions.size);
        setPageOption(response);
    };

    const refreshPage = async () => {
        const response = await agent.ProductCategory.all('', '', 0, pageOptions.size);
        setPageOption(response);
    };

    const handleReset = async (e) => {
        e.preventDefault();
        refreshPage();
        setFilter({category: '', code: ''});
    };

    return (
        <div className="product-category-block">
            <Toast ref={toastRef} />
            <div className="product-category-head">
                <h2>Категория товаров по услугам</h2>
                <div className="p-fluid p-formgrid p-grid" style={{alignItems: "flex-end"}}>
                    <div className="p-field p-col-12 p-md-3">
                        <label htmlFor="category">Категория товара</label>
                        <InputText 
                            id="category" 
                            value={filter.category} 
                            onKeyDown={(e) => e.code === "Enter" && handleSearch()}
                            onChange={(e) => handleFilterChange('category', e.target.value)}
                        />
                    </div>
                    <div className="p-field p-col-12 p-md-3 p-ml-3">
                        <label htmlFor="code">CODE</label>
                        <InputText
                            id="code"
                            value={filter.code}
                            onKeyDown={(e) => e.code === "Enter" && handleSearch()}
                            onChange={(e) => handleFilterChange('code', e.target.value)}
                        />
                    </div>
                </div>
                <div className="product-category-buttons">
                    <div className="product-category-button">
                        <Button onClick={handleSearch} type="submit" label="Найти" />
                    </div>
                    <div className="product-category-button">
                        <Button onClick={handleReset} type="reset" label="Сбросить" className="p-button-warning" />
                    </div>
                </div>
            </div>
            <div className="product-category-foot">
                <div className={styles.buttons}>
                {csvFile
                    ? <div className="p-d-flex">
                        <Button icon="pi pi-times" label={`Отменить`} onClick={clearCsvFile} className="p-button-text p-mr-2" />
                        <Button icon="pi pi-check" label={`Отправить ${csvFile.name}`} onClick={handleFileUpload} loading={isSendLoading}/>
                        </div>
                    : <FileUpload ref={fileUploadRef} chooseLabel="Выбрать CSV файл" name="testFile"
                        customUpload mode="basic" accept=".csv" onSelect={handleFileSelect}
                    />
                }   
                </div>
                <div className={styles.buttons}>
                    <button onClick={() => setModalView(modals[0])} className="product-category-add-btn">+ Добавить категорию товара</button>
                </div>
            </div>
            <Suspense fallback={<div>Loading...</div>}>
                {modalView===modals[0] || modalView===modals[1] ? 
                    <Modal  
                        onSuccess={refreshPage}
                        showMessage={showMessage} 
                        modalType={modalView} 
                        close={() => setModalView('')} 
                    /> : null
                }
  	        </Suspense>
        </div>
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(Header);        