import React from "react";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { Fieldset } from "primereact/fieldset";
import { tariffTypes } from "./mockData/tariffTypes";
import agent from "../../../agent";
import { history } from '../../../store';

export const SearchFilters = ({
  filtersBundle
}) => {
  const {
    onPostalServicePicked,
    reduxCalcTariff,
    psZones,
    filteredPsZones, 
    setFilteredPsZones
  } = filtersBundle;

  const [filtersCollapsed, setFiltersCollapsed] = React.useState(false);
  const onFiltersToggle = () => { setFiltersCollapsed(prev => !prev) };

  const reduxPostalServices = reduxCalcTariff.postalServices;
  const reduxUslugaPs = reduxCalcTariff.uslugaPs;
  const [postalServices, setPostalServices] = React.useState(reduxPostalServices);
  const [uslugaPs, setUslugaPs] = React.useState(reduxUslugaPs);

  const uslugaPsReqCountRef = React.createRef(0);

  const createQuery = (ps, tariffId, tariffType) => {
    return "ps=" + (ps ? ps : "") +
           "&tariffId=" + (tariffId ? tariffId : "") +
           "&tariffType=" + (tariffType ? tariffType : "");
  }

  const [filterPostalService, setFilterPostalService] = React.useState(undefined);
  const onPostalServicePick = (e) => {
    setFilterPostalService(e.value) 

    /* reset because it depends on postal service */
      setFilterPostalTariffId(undefined);

      if (filterPostalService) {
        const query = createQuery(
          filterPostalService, undefined, filterTariffType
        );
        history.push("/calc-tariffs?" + query)
      }
    /* /reset because it depends on postal service */

    onPostalServicePicked(
      agent.CalcTariff.getUslugaPs(e.value)
    )
  };
  
  const [filterPostalTariffId, setFilterPostalTariffId] = React.useState(undefined);
  const onPostalTariffIdPick = (e) => { setFilterPostalTariffId(e.value) };

  const [filterTariffType, setFilterTariffType] = React.useState(undefined);
  const onTariffTypePick = (e) => { setFilterTariffType(e.value) };

  const onFiltersReset = (e) => {
    e.preventDefault(); 

    setFilterPostalService(undefined);
    setFilterPostalTariffId(undefined);
    setFilterTariffType(undefined);
    setFilteredPsZones(psZones);

    const query = createQuery(undefined, undefined, undefined);
    history.push("/calc-tariffs?" + query)
  };

  const onFiltersSubmit = (e) => { 
    e.preventDefault(); 

    setFilteredPsZones(
      psZones.filter(zone => {
        return (filterPostalService ? zone.postalServiceDto.id == filterPostalService : true) && 
               (filterPostalTariffId ? zone.postalTariffId == filterPostalTariffId : true) &&
               (filterTariffType ? zone.tariffType == filterTariffType : true)
      })
    );

    const query = createQuery(
      filterPostalService, filterPostalTariffId, filterTariffType
    );

    history.push("/calc-tariffs?" + query);
  };

  const getQueryBundle = () => {
    const params = new URLSearchParams(history.location.search);

    if (params) {
      const qPs = params.get("ps") ? Number(params.get("ps")) : undefined; 
      const qTariffId = params.get("tariffId") ? Number(params.get("tariffId")) : undefined; 
      const qTariffType = params.get("tariffType") ? params.get("tariffType") : undefined;

      // request tariffId by postal service
      // onPostalServicePicked - dispatching function from props
      if (qPs && !uslugaPs.length && uslugaPsReqCountRef.current < 1) { 
        onPostalServicePicked(agent.CalcTariff.getUslugaPs(qPs));
        uslugaPsReqCountRef.current += 1; // shooting twice without ref
      }

      return { qPs, qTariffId, qTariffType };  
    }
    else { return { qPs: undefined, qTariffId: undefined, qTariffType: undefined } }
  }

  React.useEffect(() => {
    const { qPs, qTariffType } = getQueryBundle();

    setFilterPostalService(qPs);
    setFilterTariffType(qTariffType);
  }, []);

  // setting filters from url query
  React.useEffect(() => {
    const { qTariffId } = getQueryBundle();
    
    // setting tariff id after requesting uslugaPs in getQueryBundle()
    if (!filterPostalTariffId && qTariffId && uslugaPs.length) {
      setFilterPostalTariffId(qTariffId);
    }
  }, [uslugaPs]);

  React.useEffect(() => {
    if (psZones.length) {
      const { qPs, qTariffId, qTariffType } = getQueryBundle();

      setFilteredPsZones(
        psZones.filter(zone => {
          return (qPs ? zone.postalServiceDto.id == qPs : true) && 
                 (qTariffId ? zone.postalTariffId == qTariffId : true) &&
                 (qTariffType ? zone.tariffType == qTariffType : true)
        })
      );
    }
  }, [psZones])

  React.useEffect(() => {
    if (reduxCalcTariff.postalServices) { setPostalServices(reduxPostalServices) }
    if (reduxCalcTariff.uslugaPs) { setUslugaPs(reduxUslugaPs) }
  }, [reduxCalcTariff]);

  return (
    <Fieldset legend="Фильтры" toggleable collapsed={filtersCollapsed}
              onToggle={onFiltersToggle} style={{marginBottom: "10px"}}
    >
      <form onSubmit={onFiltersSubmit}>
        <div className="p-fluid p-formgrid p-grid">
          <div className="p-field p-col-6 p-md-3">
            <label htmlFor="filterPostalService">Грузоперевозчик</label>
            <Dropdown name="filterPostalService" optionLabel="nameRu" optionValue="id" 
                      value={filterPostalService} options={postalServices}
                      onChange={onPostalServicePick} />
          </div>

          <div className="p-field p-col-6 p-md-3">
            <label htmlFor="filterPostalTariffId">Услуга грузоперевозчика</label>
            <Dropdown name="filterPostalTariffId" optionLabel="nameRu" optionValue="id" 
                      value={filterPostalTariffId} options={uslugaPs}
                      onChange={onPostalTariffIdPick} 
                      disabled={!filterPostalService} 
                      placeholder={!filterPostalService ? 'Выберите грузоперевозчика' : ''} />
          </div>
        
          <div className="p-field p-col-6 p-md-3">
            <label htmlFor="filterTariffType">Тип тарифа</label>
            <Dropdown name="filterTariffType" optionLabel="label" optionValue="value" 
                      value={filterTariffType} options={tariffTypes}
                      onChange={onTariffTypePick} />
          </div>
        </div>
          
        <br />
        <div style={{display: "flex", justifyContent: "space-between"}}>
          <div className="p-grid">
            <div className="p-mr-5">
              <span>Общее количество тарифных зон: {psZones.length || 0}</span>
            </div>
            <div>
              <span>Отфильтровано тарифных зон: {filteredPsZones.length || 0}</span>
            </div>
          </div>

          <div className="p-grid">
            <div className="p-mr-2">
              <Button type="submit" label="Отфильтровать" autoFocus />
            </div>
            <div>
              <Button className="p-button-warning" type="reset" label="Сбросить" 
                      onClick={onFiltersReset} />
            </div>
          </div>
        </div>
      </form>
    </Fieldset>
  )
}