import ListErrors from './ListErrors';
import React from 'react';
import agent from '../agent';
import { connect } from 'react-redux';
import {
  UPDATE_FIELD_AUTH,
  LOGIN,
  LOGIN_PAGE_UNLOADED
} from '../constants/actionTypes';
import {InputText} from "primereact/inputtext";
import {Button} from "primereact/button";

const mapStateToProps = state => ({ 
  ...state.auth, 
  errors: state.error.messages, 
  currentUser: state.common.currentUser 
});

const mapDispatchToProps = dispatch => ({
  onChangeEmail: value =>
    dispatch({ type: UPDATE_FIELD_AUTH, key: 'email', value }),
  onChangePassword: value =>
    dispatch({ type: UPDATE_FIELD_AUTH, key: 'password', value }),
  onSubmit: (email, password) =>
    dispatch({ type: LOGIN, payload: agent.Auth.login(email, password) }),
  onUnload: () =>
    dispatch({ type: LOGIN_PAGE_UNLOADED }),
});

class Login extends React.Component {
  constructor() {
    super();
    this.changeEmail = ev => this.props.onChangeEmail(ev.target.value);
    this.changePassword = ev => this.props.onChangePassword(ev.target.value);
    this.submitForm = (email, password) => ev => {
      ev.preventDefault();
      this.props.onSubmit(email, password);
    };
  }

  componentWillUnmount() {
    this.props.onUnload();
  }

  render() {
    const email = this.props.email||'';
    const password = this.props.password||'';

    if (!this.props.currentUser) {
      return (
        <div className="p-field p-col-12 p-md-4 p-md-offset-4">
          <div className="p-d-flex p-jc-center">
            <h1>Вход</h1>
          </div>
          <ListErrors errors={this.props.errors} />

          <form onSubmit={this.submitForm(email, password)}>
            <div className="p-fluid">
              <div className="p-field">
                <InputText name="username" type="text" placeholder={"Email"} value={email} onChange={this.changeEmail}/>
              </div>
              <div className="p-field">
                <InputText name="password" type="password" placeholder={"Пароль"} value={password} onChange={this.changePassword}/>
              </div>
              <div className="p-field">
                <div className="p-col-12 p-md-4 p-md-offset-8">
                  <Button type="submit" label={"Войти"}/>
                </div>
              </div>
            </div>
          </form>
        </div>
      );
    } else {
      return null
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Login);
