import agent from '../agent';
import React, {useEffect} from 'react';
import { connect } from 'react-redux';
import {APP_LOAD, LOGOUT, REDIRECT} from '../constants/actionTypes';
import { Route, Switch } from 'react-router-dom';
import Login from '../components/Login';
import Register from '../components/Register';
import { store } from '../store';
import { push } from 'react-router-redux';
import Admin from "./Admin";
import PostalService from "./Admin/PostalService";
import PostalRoute from "./Admin/PostalRoute";
import ProductCategory from "./Admin/ProductCategory/index";
import Orders from "./Order/index";
import Order from "./Order/Order";
import Calculator from './Calculator';
import { ScrollTop } from 'primereact/scrolltop';

import 'primeicons/primeicons.css';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeflex/primeflex.css';
import Navbar from "./Header/Navbar";
import UserSettings from "./User";
import SLQUser from "./Admin/SLQUser";
import SLQUsers from "./Admin/SLQUsers";
import Terminals from "./Terminals";
import CalcTariffs from './Admin/CalcTariffs';
import CalcTariff from './Admin/CalcTariff';

const mapStateToProps = state => {
  return {
    appLoaded: state.common.appLoaded,
    appName: state.common.appName,
    currentUser: state.common.currentUser,
    redirectTo: state.common.redirectTo
  }};

const mapDispatchToProps = dispatch => ({
  onClickLogout: () => dispatch({ type: LOGOUT }),
  onLoad: (payload, token) =>
    dispatch({ type: APP_LOAD, payload, token, skipTracking: true }),
  onRedirect: () =>
    dispatch({ type: REDIRECT })
});

class App extends React.Component {

  componentDidUpdate(prevProps, prevState) {
    if (prevProps !== this.props && this.props.redirectTo) {
      store.dispatch(push(this.props.redirectTo));
      this.props.onRedirect();
    }
  }

  componentDidMount() {
    const token = window.localStorage.getItem("sid");
    agent.setToken(token);
    this.props.onLoad(token ? agent.Auth.current() : null, token);
  }
  
  render() {
    if (this.props.appLoaded) {
      return (
        <div>
          <Navbar
            appName={this.props.appName}
            currentUser={this.props.currentUser}
            logoutHandler={this.props.onClickLogout}
          />
          <div id="page-wrapper">
            <Switch>
              <Route exact path="/" component={Login} />
              <Route path="/login" component={Login} />
              <Route path="/register" component={Register} />
              <Route path="/admin" component={Admin} />
              <Route path="/postal_services" component={PostalService} />
              <Route path="/postal_routes" component={PostalRoute} />
              <Route path="/product_categories" component={ProductCategory} />
              <Route path="/orders" component={Orders} />
              <Route path="/order/:orderNum" component={Order} />
              <Route path="/calculator" component={Calculator} />
              <Route path="/settings" component={UserSettings} />
              <Route path="/users" component={SLQUsers} />
              <Route path="/terminals" component={Terminals} />
              <Route path="/user/:guid" component={SLQUser} />
              <Route path="/calc-tariffs" component={CalcTariffs} />
              <Route path="/calc-tariff/:tid" component={CalcTariff} />
            </Switch>
            <ScrollTop />
          </div>
        </div>
      );
    }
    return (
      <div>
        <Navbar
          appName={this.props.appName}
          currentUser={this.props.currentUser}
        />
      </div>
    );
  }
}

// App.contextTypes = {
//   router: PropTypes.object.isRequired
// };

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(App);
